// src/services/userService.js
import apiService from './apiService';
import authService from './authService';

export const getAuthToken = async () => {
  try {
    const token = await authService.getToken();
    return token;
  } catch (error) {
    console.error('Erro ao obter Token', error);
    throw error;
  }
};

export const getUsers = async () => {
  try {
    const response = await apiService.api.get(`/api/users`);
    return response.data;
  } catch (error) {
    console.error('Erro ao buscar usuários:', error);
    throw error;
  }
};

export const getUserById = async (userId) => {
  try {
    const response = await apiService.api.get(`/api/users/${userId}`);
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const addUser = async (user) => {
  const response = await apiService.api.post('/api/users/add-user', user);
  return response.data;
};

export const updateUser = async (userId, updatedUser) => {
  try {
    const response = await apiService.api.put(`/api/users/update-user/${userId}`, updatedUser);
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const uploadProfilePicture = async (userId, file) => {
  try {
    const formData = new FormData();
    formData.append('profilePicture', file);
    const response = await apiService.apiUpload.put(`/api/users/upload-profile-picture/${userId}`, formData);
    return response.data;
  } catch (error) {
    console.error('Erro ao fazer upload da foto de perfil:', error);
    throw error;
  }
};

export const deleteUser = async (id) => {
  const response = await apiService.api.delete(`/api/users/delete-user/${id}`);
  return response.data;
};

export default {
  getAuthToken,
  getUsers,
  getUserById,
  addUser,
  updateUser,
  uploadProfilePicture,
  deleteUser,
};