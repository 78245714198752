// src/services/connectionService.js
import apiService from './apiService';

const connectionService = {
  /**
   * Obtém conexões e melhores amigos de um usuário.
   * @param {string} userId - ID do usuário.
   * @returns {Promise<Object>} - Dados das conexões do usuário.
   */
  getConnectionsByUserId: async (userId) => {
    try {
      const response = await apiService.api.get(`/api/connections/active/user/${userId}`);
      return response.data;
    } catch (error) {
      console.error('Erro ao obter conexões:', error);
      throw error;
    }
  },

   /**
   * Atualiza uma conexão ativa, incluindo status de melhor amigo
   * @param {string} connectionId - ID da conexão
   * @param {Object} updateData - Dados a serem atualizados
   * @returns {Promise<Object>} - Conexão atualizada
   */
   updateActiveConnection: async (connectionId, updateData) => {
    try {
      const response = await apiService.api.put(`/api/connections/active/${connectionId}`, updateData);
      return response.data;
    } catch (error) {
      console.error('Erro ao atualizar conexão:', error);
      throw error;
    }
  },

 /**
   * Adiciona um amigo à lista de melhores amigos
   * @param {string} connectionId - ID da conexão
   * @returns {Promise<Object>}
   */
  addBestFriend: async (connectionId) => {
    try {
      return await connectionService.updateActiveConnection(connectionId, {
        isBestFriend: true,
        updatedAt: new Date().toISOString()
      });
    } catch (error) {
      console.error('Erro ao adicionar melhor amigo:', error);
      throw error;
    }
  },

  /**
   * Remove um amigo da lista de melhores amigos
   * @param {string} connectionId - ID da conexão
   * @returns {Promise<Object>}
   */
  removeBestFriend: async (connectionId) => {
    try {
      return await connectionService.updateActiveConnection(connectionId, {
        isBestFriend: false,
        updatedAt: new Date().toISOString()
      });
    } catch (error) {
      console.error('Erro ao remover melhor amigo:', error);
      throw error;
    }
  },

  /**
   * Deleta uma conexão ativa.
   * @param {string} friendId - ID do amigo a ser removido.
   */
  deleteActiveConnection: async (friendId) => {
    try {
      const response = await apiService.api.delete(`/api/connections/active/${friendId}`);
      return response.data;
    } catch (error) {
      console.error('Erro ao deletar conexão ativa:', error);
      throw error;
    }
  },

  /**
   * Cria uma nova conexão ativa.
   * @param {string} friendId - ID do amigo a ser conectado.
   * @returns {Promise<Object>} - Nova conexão criada.
   */
  createActiveConnection: async (friendId) => {
    try {
      const response = await apiService.api.post('/api/connections/active', { friendId });
      return response.data;
    } catch (error) {
      console.error('Erro ao criar conexão ativa:', error);
      throw error;
    }
  },

  /**
   * Envia uma solicitação de amizade.
   * @param {string} userId - ID do usuário que envia a solicitação.
   * @param {string} friendId - ID do amigo a ser convidado.
   * @returns {Promise<Object>} - Dados da solicitação enviada.
   */
  createRequestConnection: async (userId, friendId) => {
    try {
      const response = await apiService.api.post('/api/connections/request', { userId, friendId });
      return response.data;
    } catch (error) {
      console.error('Erro ao criar solicitação de amizade:', error);
      throw error;
    }
  },

  /**
   * Busca usuários com base em um termo de pesquisa.
   * @param {string} query - Termo de busca.
   * @returns {Promise<Array>} - Lista de usuários encontrados.
   */
  searchUsers: async (query) => {
    try {
      const response = await apiService.api.get(`/api/connections/search`, { params: { q: query } });
      return response.data;
    } catch (error) {
      console.error('Erro ao buscar usuários:', error);
      throw error;
    }
  },
};

export default connectionService;