// src/components/Layout/Layout.js
import React from 'react';
import { useLocation } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import TopNavbar from './TopNavBar';
import Sidebar from './Sidebar';
import Box from '@mui/material/Box';

const Layout = ({ children }) => {
  const location = useLocation();
  const theme = useTheme();

  const getBackgroundImage = () => {
    switch (location.pathname) {
      case '/login':
        return theme.palette.background.images.login;
      case '/':
        return theme.palette.background.images.home;
      default:
        return theme.palette.background.images.default;
    }
  };

  const layoutStyle = {
    minHeight: '100vh',
    backgroundImage: getBackgroundImage(),
    backgroundSize: 'cover',
    backgroundPosition: 'center',
  };

  return (
    <Box sx={{ display: 'flex' }}>
      <TopNavbar />
      <Sidebar />
      <Box component="main" sx={{ flexGrow: 1, p: 3 }} mt={5}>
        <div style={layoutStyle}  mt={5}>{children}</div>
      </Box>
    </Box>
  );
};

export default Layout;