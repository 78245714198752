import React from 'react';
import { Routes, Route, Outlet } from 'react-router-dom';
import HomePage from './components/Pages/HomePage';
import Login from './components/Auth/Login';
import Register from './components/Auth/Register';
import Dashboard from './components/Dashboard/Dashboard';
import Profile from './components/Profiles/Profile';
import NotificationHistory from './components/Notification/NotificationHistory';
import PrivateRoute from './components/Auth/PrivateRoute';
import FriendsPage from './components/Connections/FriendsPage';
import ChatWindow from './components/Messages/ChatWindow';
import ChatLayout from './components/Messages/ChatLayout';
import SelectConversation from './components/Messages/SelectConversation';
import InvitationValidation from './components/Invites/InvitationValidation';
import Layout from './components/Layout/Layout';
import CaixinhaPage from './components/Caixinhas/CaixinhaPage';
import SellerDashboard from './components/Dashboard/SellerDashboard';
import AuthCallback from './components/Auth/AuthCallback';
import PrivacyPolicy from './components/Pages/Privacy';
import TermsOfUse from './components/Pages/Terms';

const AuthenticatedLayout = () => (
  <Layout>
    <Outlet />
  </Layout>
);

const AppRoutes = () => {
  return (
    <Routes>
      <Route path="/login" element={<Login />} />
      <Route path="/register" element={<Register />} />
      <Route path="/privacy" element={<PrivacyPolicy />} />
      <Route path="/terms" element={<TermsOfUse />} />

      <Route path="/auth/callback" element={<AuthCallback />} />

      <Route element={<PrivateRoute element={<AuthenticatedLayout />} />}>
        <Route path="/dashboard" element={<Dashboard />} />
        <Route path="/notifications" element={<NotificationHistory />} />
        <Route path="/profile/:uid" element={<Profile />} />
        <Route path="/connections" element={<FriendsPage />} />
        <Route path="/caixinha" element={<CaixinhaPage />} />
        <Route path="/vendedor" element={<SellerDashboard />} />
        <Route path="/chat" element={<ChatLayout />}>
          <Route index element={<SelectConversation />} />
          <Route path=":uidDestinatario" element={<ChatWindow />} />
        </Route>
      </Route>
      <Route path="/" element={<HomePage />} />
      <Route path="/invite/validate/:inviteId" element={<InvitationValidation />} />
    </Routes>
  );
};

export default AppRoutes;