import React, { createContext, useContext, useCallback, useMemo } from 'react';
import notificationService from '../services/notificationService';
import { useAuth } from './AuthContext';
import { showToast, showPromiseToast } from '../utils/toastUtils';
import { useCachedResource, globalCache } from '../utils/cacheManager';
import { PollingService } from '../utils/pollingService';

const NotificationContext = createContext();

// Cache constants
const NOTIFICATIONS_CACHE_TIME = 5 * 60 * 1000; // 5 minutes
const NOTIFICATIONS_STALE_TIME = 30 * 1000; // 30 seconds
const POLLING_INTERVAL = 30 * 1000; // 30 seconds

const pollingService = new PollingService();

export const NotificationProvider = ({ children }) => {
  const { currentUser } = useAuth();
  const userId = currentUser?.uid;

  // Cache key for notifications
  const cacheKey = useMemo(() => 
    userId ? `notifications:${userId}` : null
  , [userId]);

  // Fetch notifications function
  const fetchNotifications = useCallback(async () => {
    if (!userId) return [];
    
    try {
      const response = await notificationService.fetchNotifications(userId);
      return response;
    } catch (error) {
      console.error('Error fetching notifications:', error);
      showToast('Error loading notifications', { type: 'error' });
      throw error;
    }
  }, [userId]);

  // Use cached resource for notifications
  const {
    data: notifications,
    loading,
    error,
    refetch: refetchNotifications,
    invalidate
  } = useCachedResource(
    cacheKey,
    fetchNotifications,
    {
      cacheTime: NOTIFICATIONS_CACHE_TIME,
      staleTime: NOTIFICATIONS_STALE_TIME,
      onError: (error) => {
        showToast('Error loading notifications', { type: 'error' });
      }
    }
  );

  // Setup polling for notifications
  React.useEffect(() => {
    if (userId) {
      const pollTask = () => {
        if (document.visibilityState === 'visible') {
          refetchNotifications();
        }
      };

      pollingService.addTask('notifications', pollTask, POLLING_INTERVAL);

      // Handle visibility change
      const handleVisibilityChange = () => {
        if (document.visibilityState === 'visible') {
          refetchNotifications();
        }
      };

      document.addEventListener('visibilitychange', handleVisibilityChange);

      return () => {
        pollingService.removeTask('notifications');
        document.removeEventListener('visibilitychange', handleVisibilityChange);
      };
    }
  }, [userId, refetchNotifications]);

  // Mark notification as read
  const markAsRead = useCallback(async (notificationId, type) => {
    if (!userId || !notificationId) return;

    return showPromiseToast(
      new Promise(async (resolve, reject) => {
        try {
          await notificationService.markAsRead(userId, notificationId, type);
          
          // Update cache optimistically
          const currentNotifications = globalCache.getItem(cacheKey) || [];
          const updatedNotifications = currentNotifications.map(notification =>
            notification.id === notificationId
              ? { ...notification, lida: true }
              : notification
          );
          
          globalCache.setItem(cacheKey, updatedNotifications, {
            cacheTime: NOTIFICATIONS_CACHE_TIME,
            staleTime: NOTIFICATIONS_STALE_TIME
          });
          
          resolve('Notification marked as read');
          
          // Refetch to ensure consistency
          refetchNotifications();
        } catch (error) {
          invalidate();
          console.error('Error marking notification as read:', error);
          reject('Failed to mark notification as read');
        }
      }),
      {
        loading: 'Marking as read...',
        success: 'Notification marked as read',
        error: 'Failed to mark as read'
      }
    );
  }, [userId, cacheKey, refetchNotifications, invalidate]);

  // Clear all notifications
  const clearAllNotifications = useCallback(async () => {
    if (!userId) return;

    return showPromiseToast(
      new Promise(async (resolve, reject) => {
        try {
          await notificationService.clearAllNotifications(userId);
          
          // Clear cache
          globalCache.setItem(cacheKey, [], {
            cacheTime: NOTIFICATIONS_CACHE_TIME,
            staleTime: NOTIFICATIONS_STALE_TIME
          });
          
          resolve('All notifications cleared');
          
          // Refetch to ensure consistency
          refetchNotifications();
        } catch (error) {
          invalidate();
          console.error('Error clearing notifications:', error);
          reject('Failed to clear notifications');
        }
      }),
      {
        loading: 'Clearing notifications...',
        success: 'All notifications cleared',
        error: 'Failed to clear notifications'
      }
    );
  }, [userId, cacheKey, refetchNotifications, invalidate]);

  // Get unread count
  const getUnreadCount = useCallback(() => {
    if (!notifications) return 0;
    return notifications.filter(notification => !notification.lida).length;
  }, [notifications]);

  const value = useMemo(() => ({
    notifications: notifications || [],
    loading,
    error,
    unreadCount: getUnreadCount(),
    markAsRead,
    clearAllNotifications,
    refetchNotifications
  }), [
    notifications,
    loading,
    error,
    getUnreadCount,
    markAsRead,
    clearAllNotifications,
    refetchNotifications
  ]);

  return (
    <NotificationContext.Provider value={value}>
      {children}
    </NotificationContext.Provider>
  );
};

export const useNotifications = () => {
  const context = useContext(NotificationContext);
  if (context === undefined) {
    throw new Error('useNotifications must be used within NotificationProvider');
  }
  return context;
};