//src/services/apiService.js
import axios from 'axios';
import authService from './authService';
import { jwtDecode } from 'jwt-decode';

const API_URL = process.env.REACT_APP_BACKEND_URL;

const createApiInstance = (contentType = 'application/json') => {
    return axios.create({
      baseURL: API_URL,
      headers: {
        'Content-Type': contentType,
      },
      withCredentials: true,
    });
  };
  
const api = createApiInstance();
const apiUpload = createApiInstance('multipart/form-data');

// Intercepta as requisições para adicionar o token de autorização
api.interceptors.request.use(async (config) => {
  try {
    let token = await authService.getToken();
    console.log('Token before request:', token);  // Logue o token obtido

    if (token) {
      const decodedToken = jwtDecode(token);
      const currentTime = Date.now() / 1000;

      if (decodedToken.exp < currentTime + 300) {  // Se o token expirar em menos de 5 minutos
        console.log('Token is close to expiration. Refreshing token...');
        token = await authService.refreshToken();
      }

      config.headers['Authorization'] = `Bearer ${token}`;
    } else {
      console.log('Token not found.');
    }
  } catch (error) {
    console.error('Error obtaining or refreshing token:', error);
  }
  return config;
}, (error) => Promise.reject(error));

// Intercepta as respostas para lidar com erros
api.interceptors.response.use(response => response, async (error) => {
  if (error.response) {
    switch (error.response.status) {
      case 401:
        try {
          const newToken = await authService.refreshToken();
          error.config.headers['Authorization'] = `Bearer ${newToken}`;
          return api.request(error.config); // Tenta novamente com o novo token
        } catch (refreshError) {
          await authService.logout();
          throw new Error(`Erro ao refresh token: ${refreshError.message}`); // add a more specific error message
        }
      case 429:
        const retryAfter = error.response.headers['retry-after'] || 1;
        const retryCount = error.config.__retryCount || 0;
        if (retryCount < 5) {
          error.config.__retryCount = retryCount + 1;
          const delay = Math.pow(2, retryCount) * retryAfter * 1000;
          return new Promise(resolve => setTimeout(() => resolve(api.request(error.config)), delay));
        }
        throw new Error('Excedemos o limite de requisições. Por favor, tente novamente mais tarde.');
      case 500:
        throw new Error('Ocorreu um erro no servidor. Por favor, tente novamente mais tarde.');
      default:
        throw error;
    }
  }
  throw error;
});

apiUpload.interceptors.request.use(api.interceptors.request.handlers[0].fulfilled, api.interceptors.request.handlers[0].rejected);

// Exporte as instâncias criadas
export default { api, apiUpload };