import React, {useMemo} from 'react';
import { AppBar, Toolbar, Box, useTheme, Badge, Tooltip, IconButton } from '@mui/material';
import { GiLockedChest, GiThreeFriends, GiConversation, GiRingingBell, GiShoppingCart } from "react-icons/gi";
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useAuth } from '../../context/AuthContext';
import LanguageSwitcher from '../../LanguageSwitcher';
import UserProfileDropdown from './UserProfileDropdown';
import { useNotifications } from '../../context/NotificationContext';

const imgLogo = process.env.REACT_APP_PLACE_HOLDER_IMG;

const TopNavBar = () => {
  const { t } = useTranslation();
  const { currentUser } = useAuth(); 
  const navigate = useNavigate();
  const { notifications } = useNotifications();
  const theme = useTheme();

  // Número de notificações não lidas
  const notificationCount = useMemo(() => 
    notifications?.filter(n => !n.lida)?.length || 0,
    [notifications]
  );

  // Componentes para navegação autenticada
  const AuthenticatedNav = useMemo(() => () => (
    <>
      <Tooltip title={t('topnavbar.notifications')}>
        <IconButton
          aria-label={t('topnavbar.notifications')}
          onClick={() => navigate('/notifications')}
        >
          <Badge badgeContent={notificationCount} color="secondary">
            <GiRingingBell />
          </Badge>
        </IconButton>
      </Tooltip>
      <Tooltip title={t('topnavbar.messages')}>
        <IconButton
          aria-label={t('topnavbar.messages')}
          onClick={() => navigate('/chat')}
        >
          <GiConversation />
        </IconButton>
      </Tooltip>
      <Tooltip title={t('topnavbar.boxes')}>
        <IconButton
          aria-label={t('topnavbar.boxes')}
          onClick={() => navigate('/caixinha')}
        >
          <GiLockedChest />
        </IconButton>
      </Tooltip>
      <Tooltip title={t('topnavbar.friends')}>
        <IconButton
          aria-label={t('topnavbar.friends')}
          onClick={() => navigate('/connections')}
        >
          <GiThreeFriends />
        </IconButton>
      </Tooltip>
      <Tooltip title={t('topnavbar.shop')}>
        <IconButton
          aria-label={t('topnavbar.shop')}
          onClick={() => navigate('/shop')}
        >
          <GiShoppingCart />
        </IconButton>
      </Tooltip>
      <UserProfileDropdown />
    </>
  ), [notificationCount, navigate]);


  // Componentes para navegação não autenticada
  const UnauthenticatedNav = () => (
    <Tooltip title={t('topnavbar.login')}>
      <IconButton
        aria-label={t('topnavbar.login')}
        onClick={() => navigate('/login')}
      >
        <span>{t('topnavbar.login')}</span>
      </IconButton>
    </Tooltip>
  );

  return (
    <AppBar
      position="fixed"
      sx={{
        zIndex: theme.zIndex.drawer + 1,
        padding: '0.5rem',
        [theme.breakpoints.down('sm')]: { padding: '0.5rem' },
      }}
    >
      <Toolbar>
        {/* Logo da aplicação */}
        <Box sx={{ flexGrow: 1 }}>
          <Tooltip title={t('topnavbar.home')}>
            <IconButton
              onClick={() => navigate(currentUser ? '/dashboard' : '/')}
              aria-label={t('topnavbar.home')}
            >
              <img src={imgLogo} alt="Logo" style={{ height: '80px', margin: '-15px' }} />
            </IconButton>
          </Tooltip>
        </Box>

        {/* Navegação baseada na autenticação */}
        {currentUser ? <AuthenticatedNav /> : <UnauthenticatedNav />}

        {/* Seletor de idioma */}
        <LanguageSwitcher />
      </Toolbar>
    </AppBar>
  );
};

export default TopNavBar;