// src/context/ConnectionContext.js
import React, { createContext, useState, useEffect, useContext, useMemo, useCallback } from 'react';
import { useAuth } from './AuthContext';
import connectionService from '../services/connectionService';
import inviteService from '../services/inviteService';
import { showToast, showPromiseToast } from '../utils/toastUtils';
import { useCachedResource, globalCache } from '../utils/cacheManager';

const ConnectionContext = createContext();

// Cache configuration
const CACHE_CONFIG = {
  CONNECTIONS_KEY: 'user:connections',
  CACHE_TIME: 30 * 60 * 1000, // 30 minutes
  STALE_TIME: 5 * 60 * 1000   // 5 minutes
};

export const ConnectionProvider = ({ children }) => {
  const { currentUser } = useAuth();
  const userId = currentUser?.uid;

  // State management with proper typing
  const [state, setState] = useState({
    friends: [],
    bestFriends: [],
    invitations: [],
    loading: true,
    error: null
  });

  // Cache key for current user's connections
  const cacheKey = useMemo(() => 
    userId ? `${CACHE_CONFIG.CONNECTIONS_KEY}:${userId}` : null
  , [userId]);

  // Fetch connections data with cache support
  const fetchConnectionsData = useCallback(async () => {
    if (!userId) {
      setState(prev => ({ ...prev, loading: false }));
      return null;
    }

    try {
      const [connections, invitationsData] = await Promise.all([
        connectionService.getConnectionsByUserId(userId),
        inviteService.getSentInvitations(userId)
      ]);

      const processedData = {
        friends: connections.friends || [],
        bestFriends: connections.bestFriends || [],
        invitations: invitationsData || [],
        loading: false,
        error: null
      };

      return processedData;
    } catch (error) {
      console.error('Failed to fetch connections:', error);
      throw new Error('Erro ao carregar conexões');
    }
  }, [userId]);

  // Use cached resource for connections
  const {
    data: cachedConnections,
    loading,
    error,
    refetch: refetchConnections,
    invalidate: invalidateConnections
  } = useCachedResource(
    cacheKey,
    fetchConnectionsData,
    {
      cacheTime: CACHE_CONFIG.CACHE_TIME,
      staleTime: CACHE_CONFIG.STALE_TIME,
      onError: (error) => {
        showToast('Erro ao carregar conexões', { type: 'error' });
        setState(prev => ({
          ...prev,
          error: error.message,
          loading: false
        }));
      }
    }
  );

  // Update state when cached data changes
  useEffect(() => {
    if (cachedConnections) {
      setState(cachedConnections);
    }
  }, [cachedConnections]);

  // Best friend management
  const addBestFriend = useCallback(async (friendId) => {
    return showPromiseToast(
      (async () => {
        try {
          await connectionService.addBestFriend(userId, friendId);
          setState(prev => ({
            ...prev,
            bestFriends: [...prev.bestFriends, prev.friends.find(f => f.id === friendId)]
          }));
          
          // Update cache
          globalCache.setItem(cacheKey, state, {
            cacheTime: CACHE_CONFIG.CACHE_TIME,
            staleTime: CACHE_CONFIG.STALE_TIME
          });
          
          return 'Amigo adicionado aos melhores amigos!';
        } catch (error) {
          console.error('Erro ao adicionar melhor amigo:', error);
          throw new Error('Erro ao adicionar melhor amigo');
        }
      })(),
      {
        loading: 'Adicionando aos melhores amigos...',
        success: 'Amigo adicionado aos melhores amigos!',
        error: 'Erro ao adicionar melhor amigo'
      }
    );
  }, [userId, cacheKey, state]);

  const removeBestFriend = useCallback(async (friendId) => {
    return showPromiseToast(
      (async () => {
        try {
          await connectionService.removeBestFriend(userId, friendId);
          setState(prev => ({
            ...prev,
            bestFriends: prev.bestFriends.filter(f => f.id !== friendId)
          }));
          
          // Update cache
          globalCache.setItem(cacheKey, state, {
            cacheTime: CACHE_CONFIG.CACHE_TIME,
            staleTime: CACHE_CONFIG.STALE_TIME
          });
          
          return 'Amigo removido dos melhores amigos!';
        } catch (error) {
          console.error('Erro ao remover melhor amigo:', error);
          throw new Error('Erro ao remover melhor amigo');
        }
      })(),
      {
        loading: 'Removendo dos melhores amigos...',
        success: 'Amigo removido dos melhores amigos!',
        error: 'Erro ao remover melhor amigo'
      }
    );
  }, [userId, cacheKey, state]);

  // Connection management
  const deleteConnection = useCallback(async (friendId) => {
    return showPromiseToast(
      (async () => {
        try {
          await connectionService.deleteActiveConnection(friendId);
          setState(prev => ({
            ...prev,
            friends: prev.friends.filter(f => f.id !== friendId),
            bestFriends: prev.bestFriends.filter(f => f.id !== friendId)
          }));
          
          // Invalidate cache
          invalidateConnections();
          
          return 'Conexão removida com sucesso!';
        } catch (error) {
          console.error('Erro ao deletar conexão:', error);
          throw new Error('Erro ao deletar conexão');
        }
      })(),
      {
        loading: 'Removendo conexão...',
        success: 'Conexão removida com sucesso!',
        error: 'Erro ao remover conexão'
      }
    );
  }, [invalidateConnections]);

  const createRequestConnection = useCallback(async (targetUserId) => {
    if (!userId) {
      showToast('Usuário não autenticado', { type: 'error' });
      return;
    }

    return showPromiseToast(
      (async () => {
        try {
          await connectionService.createRequestConnection(userId, targetUserId);
          return 'Solicitação de amizade enviada!';
        } catch (error) {
          console.error('Erro ao criar solicitação de amizade:', error);
          throw new Error('Erro ao enviar solicitação de amizade');
        }
      })(),
      {
        loading: 'Enviando solicitação de amizade...',
        success: 'Solicitação de amizade enviada!',
        error: 'Erro ao enviar solicitação'
      }
    );
  }, [userId]);

  const searchUsers = useCallback(async (query) => {
    try {
      setState(prev => ({ ...prev, loading: true }));
      const results = await connectionService.searchUsers(query);
      return results;
    } catch (error) {
      console.error('Erro ao buscar usuários:', error);
      showToast('Erro ao buscar usuários', { type: 'error' });
      throw error;
    } finally {
      setState(prev => ({ ...prev, loading: false }));
    }
  }, []);

  // Context value
  const value = useMemo(() => ({
    friends: state.friends,
    bestFriends: state.bestFriends,
    invitations: state.invitations,
    loading: state.loading || loading,
    error: state.error || error,
    addBestFriend,
    removeBestFriend,
    deleteConnection,
    createRequestConnection,
    searchUsers,
    refetchConnections
  }), [
    state,
    loading,
    error,
    addBestFriend,
    removeBestFriend,
    deleteConnection,
    createRequestConnection,
    searchUsers,
    refetchConnections
  ]);

  return (
    <ConnectionContext.Provider value={value}>
      {children}
    </ConnectionContext.Provider>
  );
};

export const useConnections = () => {
  const context = useContext(ConnectionContext);
  if (context === undefined) {
    throw new Error('useConnections must be used within a ConnectionProvider');
  }
  return context;
};