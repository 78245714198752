// src/context/ThemeContext.js
import React, { createContext, useContext, useEffect, useMemo, useCallback, useState } from 'react';
import { ThemeProvider as MUIThemeProvider, createTheme, responsiveFontSizes } from '@mui/material/styles';
import { CssBaseline } from '@mui/material';
import { useMediaQuery } from '@mui/material';
import { showToast } from '../utils/toastUtils';

// Theme configuration constants
const THEME_CONFIG = {
  STORAGE_KEY: 'app_theme_preferences',
  DEFAULT_MODE: 'system',
  TRANSITION_DURATION: '0.2s'
};

// Color palette definitions
const PALETTE = {
  light: {
    primary: {
      main: '#2196f3',
      light: '#64b5f6',
      dark: '#1976d2',
      contrastText: '#ffffff'
    },
    secondary: {
      main: '#f50057',
      light: '#ff4081',
      dark: '#c51162',
      contrastText: '#ffffff'
    },
    background: {
      default: '#fafafa',
      paper: '#ffffff',
      elevation1: 'rgba(0, 0, 0, 0.05)',
      elevation2: 'rgba(0, 0, 0, 0.07)',
      elevation3: 'rgba(0, 0, 0, 0.09)'
    },
    text: {
      primary: 'rgba(0, 0, 0, 0.87)',
      secondary: 'rgba(0, 0, 0, 0.6)',
      disabled: 'rgba(0, 0, 0, 0.38)',
      hint: 'rgba(0, 0, 0, 0.38)'
    },
    divider: 'rgba(0, 0, 0, 0.12)',
    error: {
      main: '#f44336',
      light: '#e57373',
      dark: '#d32f2f',
      contrastText: '#ffffff'
    },
    warning: {
      main: '#ff9800',
      light: '#ffb74d',
      dark: '#f57c00',
      contrastText: 'rgba(0, 0, 0, 0.87)'
    },
    info: {
      main: '#2196f3',
      light: '#64b5f6',
      dark: '#1976d2',
      contrastText: '#ffffff'
    },
    success: {
      main: '#4caf50',
      light: '#81c784',
      dark: '#388e3c',
      contrastText: 'rgba(0, 0, 0, 0.87)'
    }
  },
  dark: {
    primary: {
      main: '#90caf9',
      light: '#e3f2fd',
      dark: '#42a5f5',
      contrastText: 'rgba(0, 0, 0, 0.87)'
    },
    secondary: {
      main: '#f48fb1',
      light: '#fce4ec',
      dark: '#f06292',
      contrastText: 'rgba(0, 0, 0, 0.87)'
    },
    background: {
      default: '#121212',
      paper: '#1e1e1e',
      elevation1: 'rgba(255, 255, 255, 0.05)',
      elevation2: 'rgba(255, 255, 255, 0.07)',
      elevation3: 'rgba(255, 255, 255, 0.09)'
    },
    text: {
      primary: '#ffffff',
      secondary: 'rgba(255, 255, 255, 0.7)',
      disabled: 'rgba(255, 255, 255, 0.5)',
      hint: 'rgba(255, 255, 255, 0.5)'
    },
    divider: 'rgba(255, 255, 255, 0.12)',
    error: {
      main: '#f44336',
      light: '#e57373',
      dark: '#d32f2f',
      contrastText: '#ffffff'
    },
    warning: {
      main: '#ff9800',
      light: '#ffb74d',
      dark: '#f57c00',
      contrastText: 'rgba(0, 0, 0, 0.87)'
    },
    info: {
      main: '#2196f3',
      light: '#64b5f6',
      dark: '#1976d2',
      contrastText: '#ffffff'
    },
    success: {
      main: '#4caf50',
      light: '#81c784',
      dark: '#388e3c',
      contrastText: 'rgba(0, 0, 0, 0.87)'
    }
  }
};

// Theme context creation
const ThemeContext = createContext();

// Storage utility functions
const themeStorage = {
  save: (preferences) => {
    try {
      localStorage.setItem(THEME_CONFIG.STORAGE_KEY, JSON.stringify(preferences));
    } catch (error) {
      console.error('Error saving theme preferences:', error);
    }
  },

  load: () => {
    try {
      const stored = localStorage.getItem(THEME_CONFIG.STORAGE_KEY);
      return stored ? JSON.parse(stored) : null;
    } catch (error) {
      console.error('Error loading theme preferences:', error);
      return null;
    }
  }
};

// Theme customization utilities
const createCustomTheme = (mode, options = {}) => {
  // Create base theme with proper color palette
  const baseTheme = createTheme({
    palette: {
      mode,
      ...PALETTE[mode],
      ...(options.palette || {})
    },
    typography: {
      fontFamily: [
        '-apple-system',
        'BlinkMacSystemFont',
        '"Segoe UI"',
        'Roboto',
        '"Helvetica Neue"',
        'Arial',
        'sans-serif'
      ].join(','),
      ...(options.typography || {})
    },
    shape: {
      borderRadius: 8,
      ...(options.shape || {})
    },
    transitions: {
      duration: {
        shortest: 150,
        shorter: 200,
        short: 250,
        standard: 300,
        complex: 375,
        enteringScreen: 225,
        leavingScreen: 195
      },
      ...(options.transitions || {})
    },
    components: {
      MuiCssBaseline: {
        styleOverrides: {
          body: {
            transition: `background-color ${THEME_CONFIG.TRANSITION_DURATION} ease-in-out,
                        color ${THEME_CONFIG.TRANSITION_DURATION} ease-in-out`
          }
        }
      },
      ...(options.components || {})
    },
    ...(options.theme || {})
  });

  // Apply responsive font sizes
  return responsiveFontSizes(baseTheme);
};

export const ThemeProvider = ({ children }) => {
  // Get system preference for dark mode
  const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');
  
  // Initialize state from storage or defaults
  const [state, setState] = useState(() => {
    const stored = themeStorage.load();
    return {
      mode: stored?.mode || THEME_CONFIG.DEFAULT_MODE,
      customizations: stored?.customizations || {},
      loading: false
    };
  });

  // Determine actual theme mode based on settings and system preference
  const actualMode = useMemo(() => {
    if (state.mode === 'system') {
      return prefersDarkMode ? 'dark' : 'light';
    }
    return state.mode;
  }, [state.mode, prefersDarkMode]);

  // Create theme object with customizations
  const theme = useMemo(() => 
    createCustomTheme(actualMode, state.customizations)
  , [actualMode, state.customizations]);

  // Theme mode management
  const setThemeMode = useCallback((newMode) => {
    if (!['light', 'dark', 'system'].includes(newMode)) {
      showToast('Invalid theme mode', { type: 'error' });
      return;
    }

    setState(prev => {
      const newState = {
        ...prev,
        mode: newMode
      };
      themeStorage.save(newState);
      return newState;
    });

    showToast(`Theme mode changed to ${newMode}`, { type: 'success' });
  }, []);

  // Theme customization
  const customizeTheme = useCallback((customizations) => {
    setState(prev => {
      const newState = {
        ...prev,
        customizations: {
          ...prev.customizations,
          ...customizations
        }
      };
      themeStorage.save(newState);
      return newState;
    });
  }, []);

  // Listen for system theme changes
  useEffect(() => {
    if (state.mode === 'system') {
      setState(prev => ({ ...prev })); // Trigger re-render
    }
  }, [prefersDarkMode, state.mode]);

  // Context value
  const value = useMemo(() => ({
    mode: state.mode,
    actualMode,
    theme,
    setThemeMode,
    customizeTheme,
    isSystemMode: state.mode === 'system'
  }), [
    state.mode,
    actualMode,
    theme,
    setThemeMode,
    customizeTheme
  ]);

  return (
    <ThemeContext.Provider value={value}>
      <MUIThemeProvider theme={theme}>
        <CssBaseline />
        {children}
      </MUIThemeProvider>
    </ThemeContext.Provider>
  );
};

// Custom hook for using the theme context
export const useTheme = () => {
  const context = useContext(ThemeContext);
  if (context === undefined) {
    throw new Error('useTheme must be used within a ThemeProvider');
  }
  return context;
};