//src/services/inviteService.js
// getSentInvitations: Busca os convites enviados por um usuário específico. 
// Ele faz uma solicitação GET para a API /api/invite/sent/ passando o userId e o email do usuário como parâmetros. 
// O método não retorna nada explicitamente, mas pode ser que o desenvolvedor tenha esquecido de retornar a resposta da API.
// cancelInvitation: Cancela um convite específico. Ele faz uma solicitação DELETE para a API /api/invite/${inviteId} e retorna a resposta da API.
// resendInvitation: Reenvia um convite específico. Ele faz uma solicitação POST para a API /api/invite/${inviteId}/resend e retorna a resposta da API.
// sendInvitation: Envia um novo convite. Ele faz uma solicitação POST para a API /api/invitations passando os dados do convite como parâmetro e retorna a resposta da API.
import apiService from './apiService';

const getAuthToken = () => {
  return localStorage.getItem('authToken');
};

const inviteService = {
  getSentInvitations: async (userId) => {
    const email = localStorage.getItem('email');
    const token = localStorage.getItem('accessToken');
    console.log(localStorage.getItem('accessToken'));
    console.log(localStorage.getItem('email'));
    console.log(localStorage.getItem('userId'));      
    try {
      const response = await apiService.api.get(`/api/invite/sent/${userId}`);
      return response.data;
    } catch (error) {
      console.error('Erro ao buscar convites enviados:', error);
      throw error;
    }
  },

  getInviteById: async (inviteId) => {
    try {
      const response = await apiService.api.get(`/api/invite/view/${inviteId}`);
      return response.data;
    } catch (error) {
      console.error('Erro ao buscar detalhes do convite:', error);
      throw error;
    }
  },

  validateInvite: async (inviteId, email, nome) => {

    try {
      const response = await apiService.api.post(`/api/invite/validate/${inviteId}`, {
        inviteId: inviteId,
        email: email,
        nome: nome
      });
      return response.data;
    } catch (error) {
      console.error('Erro ao validar convite:', error);
      throw error;
    }
  },

cancelInvitation: async (inviteId) => {
  const token = getAuthToken();
  try {
    const response = await apiService.api.put(`/api/invite/cancel/${inviteId}`, {}, {
      headers: {
        'Authorization': `Bearer ${token}`
      }
    });
    return response.data;
  } catch (error) {
    console.error('Erro ao cancelar convite:', error);
    throw error;
  }
},

invalidateInvite: async (inviteToken) => {
  const token = getAuthToken();
  try {
    const response = await apiService.api.post('/api/invite/invalidate', {
      inviteToken
    }, {
      headers: {
        'Authorization': `Bearer ${token}`
      }
    });
    return response.data;
  } catch (error) {
    console.error('Erro ao invalidar convite:', error);
    throw error;
  }
},

  resendInvitation: async (inviteId, userId) => {
    const token = localStorage.getItem('accessToken');
    try {
      const response = await apiService.api.post(`/api/invite/resend/${inviteId}`, {
        body: {
        'userId': userId
        },
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
      return response.data;
    } catch (error) {
      console.error('Erro ao reenviar convite:', error);
      throw error;
    }
  },

  sendInvitation: async (invitationData) => {
    const token = localStorage.getItem('accessToken');
    try {
      const response = await apiService.api.post('/api/invite/generate', invitationData);
      console.log(response)
      return response.data;
    } catch (error) {
      console.error('Erro ao enviar convite:', error.response?.data || error.message);
      throw error;
    }
  }
};

export default inviteService;