import { toast } from 'react-toastify';
import CustomToast from '../components/Layout/CustomToast';

export const showToast = (message, options = {}) => {
  return toast(
    ({ closeToast }) => (
      <CustomToast
        closeToast={closeToast}
        toastProps={{ message, ...options }}
      />
    ),
    {
      position: "bottom-right",
      autoClose: 5000, // 5 segundos para fechar automaticamente
      hideProgressBar: false, 
      closeOnClick: true, 
      pauseOnHover: true, 
      draggable: true, 
      ...options, 
    }
  );
};

export const showPromiseToast = (promise, messages) => {
  const id = showToast(messages.loading || "Processando...", { isLoading: true });

  promise.then(() => {
    toast.update(id, {
      render: ({ closeToast }) => (
        <CustomToast
          closeToast={closeToast}
          toastProps={{
            type: "success",
            message: messages.success || "Operação concluída com sucesso!",
          }}
        />
      ),
      type: "success",
      isLoading: false,
      autoClose: 5000,
    });
  }).catch((error) => {
    toast.update(id, {
      render: ({ closeToast }) => (
        <CustomToast
          closeToast={closeToast}
          toastProps={{
            type: "error",
            message: `Erro: ${error.message}`,
            action: {
              label: "Tentar novamente",
              onClick: () => {
                // Lógica para tentar novamente
              },
            },
          }}
        />
      ),
      type: "error",
      isLoading: false,
      autoClose: 5000,
    });
  });

  return id;
};