// src/components/Auth/Login.js
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { 
  Button, 
  Container, 
  TextField, 
  Typography, 
  Box,
  IconButton, 
  Tooltip
} from '@mui/material';
import { useAuth } from '../../context/AuthContext';
import GoogleIcon from '@mui/icons-material/Google';
import FacebookIcon from '@mui/icons-material/Facebook';
import Microsoft from '@mui/icons-material/Microsoft';
import { useTranslation } from 'react-i18next';
import InterestModal from './InterestModal';

const Login = () => {
  const { t } = useTranslation();
  const year = new Date().getFullYear();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [openModal, setOpenModal] = useState(false);
  const navigate = useNavigate();
  const { 
    signInWithEmail, 
    signInWithProvider
  } = useAuth();

  const handleEmailLogin = async (event) => {
    event.preventDefault();
    try {
      await signInWithEmail(email, password);
      toast.success(t('login.login_success'));
      navigate('/dashboard');
    } catch (error) {
      toast.error(t('login.login_error', { message: error.response?.data?.message || error.message }));
    }
  };

  const handleProviderLogin = async (provider) => {
    try {
      if (provider === 'google') {
        await signInWithProvider(provider);
      } else if (provider === 'facebook') {
        await signInWithProvider(provider);
      } else if (provider === 'microsoft') {
        await signInWithProvider(provider);
      }
    } catch (error) {
      toast.error(t('login.provider_login_error', { message: error.response?.data?.message || error.message }));
    }
  };

  const handleOpenModal = () => setOpenModal(true);
  const handleCloseModal = () => setOpenModal(false);

  return (
    <Container maxWidth="lg" style={{ display: 'flex', left: 0, top: 0, bottom: 0 }}>
      <Container maxWidth="lg" style={{ marginTop: '2rem', width: '30%', marginLeft: 'auto', padding: '2rem', display: 'flex', flexDirection: 'column', justifyContent: 'center', height: '100vh' }}>
        <Typography variant="h5" style={{ marginTop: '2rem' }} gutterBottom>{t('login.login')}</Typography>
        <form onSubmit={handleEmailLogin}>
          <TextField
            label={t('login.email')}
            variant="outlined"
            fullWidth
            margin="normal"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
            autoComplete="email"
          />
          <TextField
            label={t('login.password')}
            type="password"
            variant="outlined"
            fullWidth
            margin="normal"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
            autoComplete="current-password"
          />
          <Button type="submit" variant="contained" color="primary" fullWidth style={{ marginBottom: '1rem' }}>
            {t('login.login_button')}
          </Button>
        </form>
        <Typography variant="body2" align="center" gutterBottom>{t('common.or')}</Typography>
        <Box display="flex" justifyContent="space-around" marginBottom="1rem">
          <Tooltip title={t('login.login_with_google')}>
            <IconButton onClick={() => handleProviderLogin('google')} style={{ border: '1px solid', borderColor: 'transparent' }}>
              <GoogleIcon style={{ color: '#4285F4' }} />
            </IconButton>
          </Tooltip>
          <Tooltip title={t('login.login_with_facebook')}>
            <IconButton onClick={() => handleProviderLogin('facebook')} style={{ border: '1px solid', borderColor: 'transparent' }}>
              <FacebookIcon style={{ color: '#1877F2' }} />
            </IconButton>
          </Tooltip>
          <Tooltip title={t('login.login_with_microsoft')}>
            <IconButton onClick={() => handleProviderLogin('microsoft')} style={{ border: '1px solid', borderColor: 'transparent' }}>
              <Microsoft style={{ color: '#00A4EF' }} />
            </IconButton>
          </Tooltip>
        </Box>
        <Typography variant="body2" align="center" gutterBottom style={{ color: 'gray' }}>{t('login.invite_only')}</Typography>
        <Typography variant="body2" align="center">
          <Button onClick={handleOpenModal} style={{ textTransform: 'none' }}>
            {t('login.how_to_get_invite')}
          </Button>
        </Typography>
        
      <Typography variant="body2" style={{ textAlign: 'center', color: 'grey' }} dangerouslySetInnerHTML={{ __html: t('common.footer_text', { year }) }} />
    
      </Container>
      <InterestModal open={openModal} handleClose={handleCloseModal} />
    </Container>
  );
};

export default Login;