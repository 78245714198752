// src/context/InterestsContext.js
import React, { createContext, useContext, useEffect, useMemo, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useCachedResource, globalCache } from '../utils/cacheManager';
import { showToast, showPromiseToast } from '../utils/toastUtils';
import { useAuth } from './AuthContext';

// Define cache configuration constants
const CACHE_CONFIG = {
  INTERESTS_KEY: 'user:interests',
  CACHE_TIME: 24 * 60 * 60 * 1000, // 24 hours
  STALE_TIME: 60 * 60 * 1000 // 1 hour
};

// Create the context
const InterestsContext = createContext();

// Custom hook for accessing the context
export const useInterests = () => {
  const context = useContext(InterestsContext);
  if (context === undefined) {
    throw new Error('useInterests must be used within an InterestsProvider');
  }
  return context;
};

// Provider component
export const InterestsProvider = ({ children }) => {
  const { t, i18n } = useTranslation();
  const { currentUser } = useAuth();
  const userId = currentUser?.uid;

  // Initialize state with proper structure
  const [state, setState] = useState({
    pessoais: [],
    negocios: [],
    loading: false,
    error: null,
    selectedInterests: new Set()
  });

  // Cache key for current user's interests
  const cacheKey = useMemo(() => 
    userId ? `${CACHE_CONFIG.INTERESTS_KEY}:${userId}` : null
  , [userId]);

  // Define interest categories with their translations
  const interestCategories = useMemo(() => ({
    pessoais: [
      {
        id: 'viagensGrupo',
        translationKey: 'interestsLists.pessoais.groupTrips',
        icon: '✈️'
      },
      {
        id: 'projetosSustentabilidade',
        translationKey: 'interestsLists.pessoais.sustainability',
        icon: '🌱'
      },
      {
        id: 'casamentosEventos',
        translationKey: 'interestsLists.pessoais.weddings',
        icon: '💒'
      },
      {
        id: 'educacaoCursosColetivos',
        translationKey: 'interestsLists.pessoais.education',
        icon: '📚'
      },
      {
        id: 'esportesAventura',
        translationKey: 'interestsLists.pessoais.sports',
        icon: '⚽'
      },
      {
        id: 'apoioCausasSociais',
        translationKey: 'interestsLists.pessoais.socialCauses',
        icon: '🤝'
      },
      {
        id: 'colecionismoHobbies',
        translationKey: 'interestsLists.pessoais.hobbies',
        icon: '🎨'
      },
      {
        id: 'tecnologiaGadgets',
        translationKey: 'interestsLists.pessoais.technology',
        icon: '💻'
      },
      {
        id: 'iniciativasBemEstar',
        translationKey: 'interestsLists.pessoais.wellbeing',
        icon: '🧘'
      },
      {
        id: 'comunidadesLocais',
        translationKey: 'interestsLists.pessoais.localCommunities',
        icon: '🏘️'
      }
    ],
    negocios: [
      {
        id: 'prestacaoServicos',
        translationKey: 'interestsLists.negocios.services',
        icon: '🛠️'
      },
      {
        id: 'consultoriaMentoria',
        translationKey: 'interestsLists.negocios.consulting',
        icon: '💼'
      },
      {
        id: 'vendasProdutosArtesanais',
        translationKey: 'interestsLists.negocios.crafts',
        icon: '🎨'
      },
      {
        id: 'deliveryLogisticaLocal',
        translationKey: 'interestsLists.negocios.delivery',
        icon: '🚚'
      },
      {
        id: 'marketingDigital',
        translationKey: 'interestsLists.negocios.digitalMarketing',
        icon: '📱'
      },
      {
        id: 'servicosAlimentacao',
        translationKey: 'interestsLists.negocios.foodServices',
        icon: '🍽️'
      },
      {
        id: 'tecnologiaDesenvolvimento',
        translationKey: 'interestsLists.negocios.softwareDevelopment',
        icon: '💻'
      },
      {
        id: 'educacaoTreinamento',
        translationKey: 'interestsLists.negocios.education',
        icon: '📚'
      },
      {
        id: 'designCriacaoMarca',
        translationKey: 'interestsLists.negocios.design',
        icon: '🎨'
      },
      {
        id: 'coworkingEspacos',
        translationKey: 'interestsLists.negocios.coworking',
        icon: '🏢'
      }
    ]
  }), []);

  // Transform interests data with translations
  const transformInterests = useCallback((category) => {
    return interestCategories[category].map(interest => ({
      label: t(`${interest.translationKey}.label`),
      value: interest.id,
      description: t(`${interest.translationKey}.description`),
      icon: interest.icon
    }));
  }, [t, interestCategories]);

  // Update interests lists when language changes
  useEffect(() => {
    if (!state.loading) {
      setState(prev => ({
        ...prev,
        pessoais: transformInterests('pessoais'),
        negocios: transformInterests('negocios')
      }));
    }
  }, [i18n.language, transformInterests, state.loading]);

  // Fetch user's selected interests from backend
  const fetchUserInterests = useCallback(async () => {
    if (!userId) return null;

    try {
      setState(prev => ({ ...prev, loading: true }));
      // Here you would typically make an API call to fetch user's interests
      // const response = await interestsService.getUserInterests(userId);
      const selectedInterests = new Set(/* response.interests */);
      
      return {
        pessoais: transformInterests('pessoais'),
        negocios: transformInterests('negocios'),
        selectedInterests,
        loading: false,
        error: null
      };
    } catch (error) {
      console.error('Error fetching user interests:', error);
      throw new Error('Failed to load user interests');
    }
  }, [userId, transformInterests]);

  // Use cached resource for user interests
  const {
    data: cachedInterests,
    loading,
    error,
    refetch: refetchInterests,
    invalidate: invalidateInterests
  } = useCachedResource(
    cacheKey,
    fetchUserInterests,
    {
      cacheTime: CACHE_CONFIG.CACHE_TIME,
      staleTime: CACHE_CONFIG.STALE_TIME,
      onError: (error) => {
        showToast('Error loading interests', { type: 'error' });
        setState(prev => ({
          ...prev,
          error: error.message,
          loading: false
        }));
      }
    }
  );

  // Update state when cached data changes
  useEffect(() => {
    if (cachedInterests) {
      setState(prev => ({
        ...prev,
        ...cachedInterests
      }));
    }
  }, [cachedInterests]);

  // Handle interest selection/deselection
  const toggleInterest = useCallback(async (interestId) => {
    return showPromiseToast(
      (async () => {
        try {
          setState(prev => {
            const newSelected = new Set(prev.selectedInterests);
            if (newSelected.has(interestId)) {
              newSelected.delete(interestId);
            } else {
              newSelected.add(interestId);
            }
            return { ...prev, selectedInterests: newSelected };
          });

          // Here you would typically make an API call to update user's interests
          // await interestsService.updateUserInterests(userId, Array.from(newSelected));

          // Update cache
          globalCache.setItem(cacheKey, state, {
            cacheTime: CACHE_CONFIG.CACHE_TIME,
            staleTime: CACHE_CONFIG.STALE_TIME
          });

          return 'Interests updated successfully';
        } catch (error) {
          console.error('Error updating interests:', error);
          throw new Error('Failed to update interests');
        }
      })(),
      {
        loading: 'Updating interests...',
        success: 'Interests updated successfully',
        error: 'Failed to update interests'
      }
    );
  }, [userId, cacheKey, state]);

  // Context value with memoization
  const value = useMemo(() => ({
    interests: {
      pessoais: state.pessoais,
      negocios: state.negocios
    },
    selectedInterests: state.selectedInterests,
    loading: state.loading || loading,
    error: state.error || error,
    toggleInterest,
    refetchInterests,
    invalidateInterests
  }), [
    state,
    loading,
    error,
    toggleInterest,
    refetchInterests,
    invalidateInterests
  ]);

  return (
    <InterestsContext.Provider value={value}>
      {children}
    </InterestsContext.Provider>
  );
};