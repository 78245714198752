import caixinhaService from './caixinhaService';
import notificationService from './notificationService';
import messageService from './messageService';
import connectionService from './connectionService';

const getDashboardData = async (userId) => {
  try {
    console.log('[Dashboard] Iniciando busca de dados:', { userId });
    
    const [
      caixinhas,
      notificationsResponse,
      messages,
      connectionsResponse
    ] = await Promise.all([
      caixinhaService.getCaixinhas(userId),
      notificationService.fetchNotifications(userId),
      messageService.fetchMessageById(userId),
      connectionService.getConnectionsByUserId(userId)
    ]);

    console.log('[Dashboard] Dados recebidos:', {
      caixinhas: caixinhas.data,
      notifications: notificationsResponse,
      messages,
      connections: connectionsResponse
    });

    const { friends = [], bestFriends = [] } = connectionsResponse || {};

    const response = {
      caixinhas: caixinhas.data.length ? caixinhas.data : [{ message: 'Você ainda não possui caixinhas registradas' }],
      notifications: notificationsResponse.length ? notificationsResponse : [{ message: 'Você ainda não possui notificações' }],
      messages: messages.length ? messages : [{ message: 'Você ainda não possui mensagens' }],
      connections: {
        friends: friends.length ? friends : [{ message: 'Você ainda não possui amigos ativos' }],
        bestFriends: bestFriends.length ? bestFriends : [{ message: 'Você ainda não possui melhores amigos' }],
      },
    };

    console.log('[Dashboard] Resposta estruturada:', response);
    return response;

  } catch (error) {
    console.error('[Dashboard] Erro:', error);
    throw new Error(`Erro ao buscar dados do dashboard: ${error.response ? error.response.data : error.message}`);
  }
};

export default getDashboardData;