import React, { createContext, useContext, useReducer, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { GoogleAuthProvider, FacebookAuthProvider, OAuthProvider, signInWithPopup, signOut, onAuthStateChanged } from 'firebase/auth';
import { showToast, showPromiseToast } from '../utils/toastUtils';
import { auth } from '../firebaseConfig';
import authService from '../services/authService';

const AuthContext = createContext();

export const useAuth = () => useContext(AuthContext);

const initialState = {
  currentUser: null,
  loading: true,
  error: null,
};

const authReducer = (state, action) => {
  switch (action.type) {
    case 'LOGIN_SUCCESS':
      return { ...state, currentUser: action.payload, loading: false, error: null };
    case 'LOGIN_FAILURE':
      return { ...state, currentUser: null, loading: false, error: action.payload };
    case 'LOGOUT':
      return { ...state, currentUser: null, loading: false, error: null };
    case 'SET_LOADING':
      return { ...state, loading: action.payload };
    case 'SET_ERROR':
      return { ...state, error: action.payload };
    default:
      return state;
  }
};

const AuthProvider = ({ children }) => {
  const [state, dispatch] = useReducer(authReducer, initialState);
  const navigate = useNavigate();
  const googleProvider = new GoogleAuthProvider();
  const facebookProvider = new FacebookAuthProvider();
  const microsoftProvider = new OAuthProvider('microsoft.com');

  // useEffect(() => {
  //   const unsubscribe = onAuthStateChanged(auth, async (user) => {
  //     if (user) {
  //       try {
  //         const token = await user.getIdToken();
  //         localStorage.setItem('accessToken', token);
          
  //         // Verificar se já temos os dados do usuário no estado
  //         if (!state.currentUser || state.currentUser.uid !== user.uid) {
  //           dispatch({ type: 'SET_LOADING', payload: true });
  //           const backendUser = await authService.getCurrentUser(token, user.uid);
  //           if (backendUser) {
  //             dispatch({ type: 'LOGIN_SUCCESS', payload: backendUser });
  //           } else {
  //             throw new Error('BackendUser não está definido.');
  //           }
  //         }
  //       } catch (error) {
  //         dispatch({ type: 'LOGIN_FAILURE', payload: error.message });
  //         showToast('Erro ao carregar dados do usuário', { type: 'error' });
  //       }
  //     } else {
  //       if (state.currentUser) {
  //         dispatch({ type: 'LOGOUT' });
  //         clearCookies();
  //         showToast('Sessão encerrada.', { type: 'info' });
  //       }
  //     }
  //     dispatch({ type: 'SET_LOADING', payload: false });
  //   });
  
  //   return () => unsubscribe();
  // }, []);

  const clearCookies = () => {
    document.cookie = "accessToken=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
    document.cookie = "refreshToken=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
  };
  
  const getCookie = (name) => {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) {
      const cookieValue = parts.pop().split(';').shift();
      console.log('Cookie encontrado:', cookieValue); 
      return cookieValue;
    }
    return null;
  };

  const signInWithProvider = async (provider) => {
    try {
      provider.setCustomParameters({ prompt: 'select_account' });
      const result = await signInWithPopup(auth, provider);
      const idToken = await result.user.getIdToken();
      
      // Adicionar o refreshToken ao cookie com HttpOnly e Secure
      const refreshToken = result.user.refreshToken;
      
      document.cookie = `refreshToken=${refreshToken}; path=/; Secure; HttpOnly`; // Armazenar no cookie
      
      const backendUser = await authService.getCurrentUser(idToken, result.user.uid);
      dispatch({ type: 'LOGIN_SUCCESS', payload: backendUser });
    
      navigate('/dashboard');
      return result.user;
    } catch (error) {
      console.error('Erro ao fazer login com provedor:', error);
      dispatch({ type: 'LOGIN_FAILURE', payload: error.message });
      throw error;
    }
  };
  
  const signInWithGoogle = () => {
    showPromiseToast(signInWithProvider(googleProvider), {
      loading: 'Entrando Com o Google',
      success: 'Login bem-sucedido!',
      error: 'Erro ao fazer login.',
    });
  };

  const signInWithFacebook = () => {
    showPromiseToast(signInWithProvider(facebookProvider), {
      loading: 'Entrando com o Facebook',
      success: 'Login bem-sucedido!',
      error: 'Erro ao fazer login.',
    });
  };

  const signInWithMicrosoft = () => {
    showPromiseToast(signInWithProvider(microsoftProvider), {
      loading: 'Entrando com a Microsoft',
      success: 'Login bem-sucedido!',
      error: 'Erro ao fazer login.',
    });
  };

  const logoutUser = async () => {
    try {
      const token = getCookie('accessToken');
      await authService.logout(token);
      await signOut(auth);
      clearCookies();
      dispatch({ type: 'LOGOUT' });
      navigate('/login');
    } catch (error) {
      console.error('Erro ao tentar deslogar:', error);
      dispatch({ type: 'SET_ERROR', payload: error.message });
      throw error;
    }
  };

  // useEffect(() => {
  //   let inactivityTimer;
  //   let warningTimer;
  
  //   const resetTimers = () => {
  //     clearTimeout(inactivityTimer);
  //     clearTimeout(warningTimer);
      
  //     // Definir timer para mostrar aviso após 25 minutos
  //     warningTimer = setTimeout(() => {
  //       showToast('Sua sessão expirará em 5 minutos. Deseja continuar?', {
  //         type: 'warning',
  //         duration: 300000, // 5 minutos
  //         action: {
  //           label: 'Continuar',
  //           onClick: () => resetTimers()
  //         }
  //       });
  //     }, 25 * 60 * 1000);
  
  //     // Definir timer para fazer logout após 30 minutos
  //     inactivityTimer = setTimeout(logoutUser, 30 * 60 * 1000);
  //   };
  
  //   window.addEventListener('mousemove', resetTimers);
  //   window.addEventListener('keydown', resetTimers);
  
  //   resetTimers();
  
  //   return () => {
  //     clearTimeout(inactivityTimer);
  //     clearTimeout(warningTimer);
  //     window.removeEventListener('mousemove', resetTimers);
  //     window.removeEventListener('keydown', resetTimers);
  //   };
  // }, []);

  // useEffect(() => {
  //   let refreshInterval;
  
  //   const refreshTokenPeriodically = async () => {
  //     try {
  //       const currentToken = localStorage.getItem('accessToken');
  //       if (currentToken) {
  //         const newTokens = await authService.refreshToken(currentToken);
  //         localStorage.setItem('accessToken', newTokens.accessToken);
  //         // Atualizar o estado do usuário se necessário
  //       }
  //     } catch (error) {
  //       console.error('Erro ao atualizar o token:', error);
  //       // Se falhar em atualizar o token, faça logout
  //       await logoutUser();
  //     }
  //   };
  
  //   // Atualizar o token a cada 25 minutos
  //   refreshInterval = setInterval(refreshTokenPeriodically, 25 * 60 * 1000);
  
  //   return () => clearInterval(refreshInterval);
  // }, []);

  const value = {
    currentUser: state.currentUser,
    loading: state.loading,
    error: state.error,
    signInWithGoogle,
    signInWithFacebook,
    signInWithMicrosoft,
    logoutUser,
  };

  return (
    <AuthContext.Provider value={value}>
      {children}
    </AuthContext.Provider>
  );
};

export { AuthContext, AuthProvider };