import React from 'react';
import AppRoutes from './routes';
import { Container } from '@mui/material';
import { ThemeProvider } from './context/themeContext';
import { UserProvider } from './context/UserContext';
import { NotificationProvider } from './context/NotificationContext';
import { ConnectionProvider } from './context/ConnectionContext';
import { InterestsProvider } from './context/InterestsContext';
import { ValidationProvider } from './context/ValidationContext'; 
import { MessageProvider } from './context/MessageContext';
import { CaixinhaProvider } from './context/CaixinhaContext';
import { BankingProvider } from './context/BankingContext';
import { PaymentProvider } from './context/PaymentContext'; 
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const App = () => {
  return (
    <ThemeProvider>
      <NotificationProvider>
        <ConnectionProvider>
          <ValidationProvider>
            <UserProvider>
              <MessageProvider> 
                <InterestsProvider>
                  <CaixinhaProvider>
                    <BankingProvider>
                      <PaymentProvider> 
                        <Container>
                          <AppRoutes />
                          <ToastContainer
                            position="bottom-right"
                            autoClose={5000}
                            hideProgressBar={false}
                            newestOnTop={false}
                            closeOnClick
                            rtl={false}
                            pauseOnFocusLoss
                            draggable
                            pauseOnHover
                          />
                        </Container>
                      </PaymentProvider>
                    </BankingProvider>
                  </CaixinhaProvider>
                </InterestsProvider>
              </MessageProvider>
            </UserProvider>
          </ValidationProvider>
        </ConnectionProvider>
      </NotificationProvider>
    </ThemeProvider>
  );
};

export default App;