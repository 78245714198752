import apiService from './apiService';

const caixinhaService = {
  // Fetch all Caixinhas for the logged-in user
  getCaixinhas: async (userId) => {
    if (!userId) {
      console.error('userId não fornecido.');
      throw new Error('userId é obrigatório para buscar caixinhas.');
    }
  
    try {
      const response = await apiService.api.get(`/api/caixinha/${userId}`);
      return response.data; 
    } catch (error) {
      console.error('Erro ao buscar caixinhas:', error.message);
      throw error;
    }
  },  

  // Fetch a single Caixinha by its ID
  getCaixinhaById: async (id) => {
    try {
      const response = await apiService.api.get(`/api/caixinha/${id}`);
      return response.data;
    } catch (error) {
      console.error(`Erro ao buscar caixinha com ID ${id}:`, error.message);
      throw error;
    }
  },

  // Create a new Caixinha
  createCaixinha: async (data) => {
    try {
      const response = await apiService.api.post('/api/caixinha', data);
      return response.data;
    } catch (error) {
      console.error('Erro ao criar caixinha:', error.message);
      throw error;
    }
  },

  // Update an existing Caixinha
  updateCaixinha: async (id, data) => {
    try {
      const response = await apiService.api.put(`/api/caixinha/${id}`, data);
      return response.data;
    } catch (error) {
      console.error(`Erro ao atualizar caixinha com ID ${id}:`, error.message);
      throw error;
    }
  },

  // Delete a Caixinha
  deleteCaixinha: async (id) => {
    try {
      const response = await apiService.api.delete(`/api/caixinha/${id}`);
      return response.data;
    } catch (error) {
      console.error(`Erro ao deletar caixinha com ID ${id}:`, error.message);
      throw error;
    }
  },

  // Add a contribution to a Caixinha
  addContribuicao: async (data) => {
    try {
      const response = await apiService.api.post('/api/caixinha/contribuicao', data);
      return response.data;
    } catch (error) {
      console.error('Erro ao adicionar contribuição:', error.message);
      throw error;
    }
  },

  // Fetch all contributions for a specific Caixinha
  getContribuicoes: async (id) => {
    try {
      const response = await apiService.api.get(`/api/caixinha/${id}/contribuicoes`);
      return response.data;
    } catch (error) {
      console.error(`Erro ao buscar contribuições para caixinha com ID ${id}:`, error.message);
      throw error;
    }
  },
};

export default caixinhaService;