// src/utils/cacheManager.js
import { useState, useEffect, useCallback } from 'react';

const DEFAULT_CACHE_TIME = 5 * 60 * 1000; // 5 minutes
const DEFAULT_STALE_TIME = 30 * 1000; // 30 seconds

export class CacheManager {
  constructor() {
    this.cache = new Map();
    this.subscribers = new Map();
  }

  setItem(key, value, options = {}) {
    const timestamp = Date.now();
    const cacheTime = options.cacheTime || DEFAULT_CACHE_TIME;
    const staleTime = options.staleTime || DEFAULT_STALE_TIME;

    this.cache.set(key, {
      value,
      timestamp,
      cacheTime,
      staleTime
    });

    // Notify subscribers
    if (this.subscribers.has(key)) {
      this.subscribers.get(key).forEach(callback => callback(value));
    }
  }

  getItem(key) {
    const item = this.cache.get(key);
    if (!item) return null;

    const { value, timestamp, cacheTime } = item;
    const now = Date.now();

    // Check if cache is expired
    if (now - timestamp > cacheTime) {
      this.cache.delete(key);
      return null;
    }

    return value;
  }

  isStale(key) {
    const item = this.cache.get(key);
    if (!item) return true;

    const { timestamp, staleTime } = item;
    const now = Date.now();

    return now - timestamp > staleTime;
  }

  subscribe(key, callback) {
    if (!this.subscribers.has(key)) {
      this.subscribers.set(key, new Set());
    }
    this.subscribers.get(key).add(callback);

    return () => {
      const subscribers = this.subscribers.get(key);
      if (subscribers) {
        subscribers.delete(callback);
        if (subscribers.size === 0) {
          this.subscribers.delete(key);
        }
      }
    };
  }

  invalidate(key) {
    this.cache.delete(key);
  }

  clear() {
    this.cache.clear();
    this.subscribers.clear();
  }
}

export const globalCache = new CacheManager();

export function useCachedResource(key, fetchFn, options = {}) {
  const [data, setData] = useState(() => globalCache.getItem(key));
  const [loading, setLoading] = useState(!data);
  const [error, setError] = useState(null);

  const fetch = useCallback(async (force = false) => {
    if (!force && data && !globalCache.isStale(key)) {
      return;
    }

    try {
      setLoading(true);
      setError(null);
      const result = await fetchFn();
      globalCache.setItem(key, result, options);
      setData(result);
    } catch (err) {
      setError(err);
      throw err;
    } finally {
      setLoading(false);
    }
  }, [key, fetchFn, data, options]);

  useEffect(() => {
    const unsubscribe = globalCache.subscribe(key, setData);
    
    if (!data || globalCache.isStale(key)) {
      fetch();
    }

    return () => unsubscribe();
  }, [key, fetch, data]);

  return {
    data,
    loading,
    error,
    refetch: () => fetch(true),
    invalidate: () => globalCache.invalidate(key)
  };
}