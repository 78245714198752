import React, { createContext, useContext, useCallback, useMemo, useState } from 'react';
import { useAuth } from './AuthContext';
import { useCachedResource, globalCache } from '../utils/cacheManager';
import userService from '../services/userService';
import { showToast } from '../utils/toastUtils';

const UserContext = createContext();

const USER_CACHE_TIME = 30 * 60 * 1000; // 30 minutes
const USER_STALE_TIME = 5 * 60 * 1000; // 5 minutes
const USERS_LIST_CACHE_KEY = 'users:list';

export const UserProvider = ({ children }) => {
  const { currentUser: authCurrentUser } = useAuth();
  const userId = authCurrentUser?.uid;
  const [usersList, setUsersList] = useState([]);

  // Cache key for current user
  const cacheKey = useMemo(() => 
    userId ? `user:${userId}` : null
  , [userId]);

  // Fetch current user data
  const fetchUserData = useCallback(async () => {
    if (!userId) return null;
    try {
      const userData = await userService.getUserById(userId);
      return userData;
    } catch (error) {
      console.error('Error fetching user data:', error);
      throw error;
    }
  }, [userId]);

  // Use cached resource for current user
  const {
    data: currentUser,
    loading,
    error,
    refetch,
    invalidate
  } = useCachedResource(
    cacheKey,
    fetchUserData,
    {
      cacheTime: USER_CACHE_TIME,
      staleTime: USER_STALE_TIME,
      onError: (error) => {
        showToast('Error loading user data', { type: 'error' });
      }
    }
  );

  // Add new user
  const addUser = useCallback(async (userData) => {
    try {
      const newUser = await userService.addUser(userData);
      
      // Update users list in cache
      const currentList = globalCache.getItem(USERS_LIST_CACHE_KEY) || [];
      const updatedList = [...currentList, newUser];
      globalCache.setItem(USERS_LIST_CACHE_KEY, updatedList);
      setUsersList(updatedList);

      showToast('User added successfully', { type: 'success' });
      return newUser;
    } catch (error) {
      showToast('Error adding user', { type: 'error' });
      throw error;
    }
  }, []);

  // Update user
  const updateUser = useCallback(async (updates) => {
    if (!userId) return;
    try {
      // Optimistic update for current user
      const updatedUser = { ...currentUser, ...updates };
      globalCache.setItem(cacheKey, updatedUser);

      // Update user in backend
      await userService.updateUser(userId, updates);
      showToast('Profile updated successfully', { type: 'success' });
      
      // Update users list if present in cache
      const usersList = globalCache.getItem(USERS_LIST_CACHE_KEY);
      if (usersList) {
        const updatedList = usersList.map(user => 
          user.id === userId ? updatedUser : user
        );
        globalCache.setItem(USERS_LIST_CACHE_KEY, updatedList);
        setUsersList(updatedList);
      }

      // Refresh cache
      refetch();
    } catch (error) {
      invalidate();
      showToast('Error updating profile', { type: 'error' });
      throw error;
    }
  }, [userId, currentUser, cacheKey, refetch, invalidate]);

  // Delete user
  const deleteUser = useCallback(async (targetUserId) => {
    try {
      await userService.deleteUser(targetUserId);
      
      // Update users list in cache
      const currentList = globalCache.getItem(USERS_LIST_CACHE_KEY) || [];
      const updatedList = currentList.filter(user => user.id !== targetUserId);
      globalCache.setItem(USERS_LIST_CACHE_KEY, updatedList);
      setUsersList(updatedList);

      // Remove user from individual cache
      globalCache.removeItem(`user:${targetUserId}`);

      showToast('User deleted successfully', { type: 'success' });
    } catch (error) {
      showToast('Error deleting user', { type: 'error' });
      throw error;
    }
  }, []);

  // Batch operations
  const batchUpdateUsers = useCallback(async (updates) => {
    try {
      // Optimistic update in cache
      const updatedUsers = await Promise.all(
        updates.map(async ({ userId, data }) => {
          const updatedUser = await userService.updateUser(userId, data);
          globalCache.setItem(`user:${userId}`, updatedUser);
          return updatedUser;
        })
      );

      // Update users list if in cache
      const usersList = globalCache.getItem(USERS_LIST_CACHE_KEY);
      if (usersList) {
        const updatedList = usersList.map(user => {
          const update = updatedUsers.find(u => u.id === user.id);
          return update || user;
        });
        globalCache.setItem(USERS_LIST_CACHE_KEY, updatedList);
        setUsersList(updatedList);
      }

      showToast('Batch update completed successfully', { type: 'success' });
      return updatedUsers;
    } catch (error) {
      showToast('Error in batch update', { type: 'error' });
      throw error;
    }
  }, []);

  // Upload profile picture
  const uploadProfilePicture = useCallback(async (file, targetUserId = userId) => {
    if (!targetUserId || !file) return;
    try {
      const updatedUser = await userService.uploadProfilePicture(targetUserId, file);
      
      // Update cache for target user
      globalCache.setItem(`user:${targetUserId}`, updatedUser);
      
      // Update users list if present in cache
      const usersList = globalCache.getItem(USERS_LIST_CACHE_KEY);
      if (usersList) {
        const updatedList = usersList.map(user => 
          user.id === targetUserId ? updatedUser : user
        );
        globalCache.setItem(USERS_LIST_CACHE_KEY, updatedList);
        setUsersList(updatedList);
      }

      showToast('Profile picture updated successfully', { type: 'success' });
      return updatedUser;
    } catch (error) {
      showToast('Error uploading profile picture', { type: 'error' });
      throw error;
    }
  }, [userId]);

  // Get user by ID with cache
  const getUserById = useCallback(async (targetUserId) => {
    if (!targetUserId) return null;
    const targetCacheKey = `user:${targetUserId}`;
    const cachedUser = globalCache.getItem(targetCacheKey);
    
    if (cachedUser && !globalCache.isStale(targetCacheKey)) {
      return cachedUser;
    }

    try {
      const user = await userService.getUserById(targetUserId);
      globalCache.setItem(targetCacheKey, user, {
        cacheTime: USER_CACHE_TIME,
        staleTime: USER_STALE_TIME
      });
      return user;
    } catch (error) {
      console.error('Error fetching user:', error);
      throw error;
    }
  }, []);

  // Get all users
  const getAllUsers = useCallback(async () => {
    try {
      const users = await userService.getAllUsers();
      globalCache.setItem(USERS_LIST_CACHE_KEY, users, {
        cacheTime: USER_CACHE_TIME,
        staleTime: USER_STALE_TIME
      });
      setUsersList(users);
      return users;
    } catch (error) {
      showToast('Error fetching users', { type: 'error' });
      throw error;
    }
  }, []);

  const contextValue = useMemo(() => ({
    currentUser,
    loading,
    error,
    usersList,
    addUser,
    updateUser,
    deleteUser,
    uploadProfilePicture,
    getUserById,
    getAllUsers,
    batchUpdateUsers,
    refetchUser: refetch
  }), [
    currentUser,
    loading,
    error,
    usersList,
    addUser,
    updateUser,
    deleteUser,
    uploadProfilePicture,
    getUserById,
    getAllUsers,
    batchUpdateUsers,
    refetch
  ]);

  return (
    <UserContext.Provider value={contextValue}>
      {children}
    </UserContext.Provider>
  );
};

export const useUser = () => {
  const context = useContext(UserContext);
  if (!context) {
    throw new Error('useUser must be used within UserProvider');
  }
  return context;
};