//src/services/notificationService.js
// fetchNotifications: Este método faz uma solicitação GET para a API para buscar notificações para um usuário específico, identificado pelo parâmetro userId. 
// Ele retorna os dados das notificações encontradas.
// markAsRead: Este método faz uma solicitação POST para a API para marcar uma notificação como lida. 
// Ele recebe três parâmetros: userId, notificationId e type. Ele envia esses parâmetros para a API em formato JSON e retorna a resposta da API.

import apiService from './apiService';

const fetchNotifications = async (userId) => {
  try {
    const response = await apiService.api.get(`/api/notifications/${userId}`)
    console.log(response)
    return response.data;
  } catch (error) {
    console.error('Erro ao buscar notificações:', error);
    throw error;
  }
};

const markAsRead = async (userId, notificationId, type) => {
  try {
    const response = await apiService.api.post(`/api/notifications/${userId}/markAsRead`, {
      userId,        
      notificationId, 
      type           
    });
    console.log('Response:', response);
    return response.data;
  } catch (error) {
    console.error('Error marking notification as read:', error);
    throw error;
  }
}

export default {
  fetchNotifications,
  markAsRead
};