import apiService from './apiService';
import { onAuthStateChanged } from 'firebase/auth';
import { auth } from '../firebaseConfig';

const authService = {
  // Register a new user
  register: async (email, password, inviteCode) => {
    try {
      const response = await apiService.api.post('/api/auth/register', { email, password, inviteCode });
      console.log('register response: ', response.data);
      return response.data;
    } catch (error) {
      throw new Error(`Erro ao registrar usuário: ${error.response ? error.response.data : error.message}`);
    }
  },

  // Login an existing user
  login: async (email, password) => {
    try {
      const response = await apiService.api.post('/api/auth/login', { email, password });
      console.log('login response: ', response.data);
      return response.data;
    } catch (error) {
      throw new Error(`Erro ao fazer login: ${error.response ? error.response.data : error.message}`);
    }
  },

  // Logout the current user
  logout: async () => {
    try {
      const token = await authService.getToken();  
      const response = await apiService.api.post('/api/auth/logout', {}, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      console.log('logout response: ', response.data);
      return response.data;
    } catch (error) {
      throw new Error(`Erro ao fazer logout: ${error.response ? error.response.data : error.message}`);
    }
  },

  // Login with a provider
  loginWithProvider: async (idToken, provider) => {
    try {
      const response = await apiService.api.post('/api/auth/login-with-provider', { idToken, provider });
      console.log('loginWithProvider response: ', response.data);
      return response.data;
    } catch (error) {
      throw new Error(`Erro ao fazer login com provedor: ${error.response ? error.response.data : error.message}`);
    }
  },

  // Register with a provider
  registerWithProvider: async (provider, inviteCode) => {
    try {
      const response = await apiService.api.post('/api/auth/register-with-provider', { provider, inviteCode });
      console.log('registerWithProvider response: ', response.data);
      return response.data;
    } catch (error) {
      throw new Error(`Erro ao registrar com provedor: ${error.response ? error.response.data : error.message}`);
    }
  },

  // Resend verification email
  resendVerificationEmail: async (email) => {
    try {
      const response = await apiService.api.post('/api/auth/resend-verification-email', { email });
      console.log('resendVerificationEmail response: ', response.data);
      return response.data;
    } catch (error) {
      throw new Error(`Erro ao reenviar e-mail de verificação: ${error.response ? error.response.data : error.message}`);
    }
  },

  // Get current user from backend
  getCurrentUser: async () => {
    return new Promise((resolve, reject) => {
      onAuthStateChanged(auth, async (user) => {
        if (user) {
          try {
            const token = await user.getIdToken();
            const backendUser = await apiService.api.get(`/api/auth/me`, {
              headers: { Authorization: `Bearer ${token}` },
            });
            resolve(backendUser.data);
          } catch (error) {
            reject(error);
          }
        } else {
          resolve(null);
        }
      });
    });
  },

  getToken: async () => {
    const user = auth.currentUser; // Certificar-se de que o usuário está autenticado
    if (!user) {
      throw new Error('Nenhum usuário autenticado');
    }
    return user.getIdToken(); // Obtenha o token do usuário autenticado
  },
  
  // Refresh token method
  refreshToken: async () => {
    try {
      const user = auth.currentUser; 
      if (user) {
        const newToken = await user.getIdToken(true);  
        return newToken;
      } else {
        throw new Error('No authenticated user');
      }
    } catch (error) {
      console.error('Error refreshing token:', error);
      throw error;
    }
  },
};

export default authService;