// src/services/messageService.js
import apiService from './apiService';

const getAuthToken = () => {
  return localStorage.getItem('authToken');
};

const fetchAllMessages = async (userId) => {
  try {
    const response = await apiService.api.get(`/api/messages?userId=${userId}`);
    console.log('Mensagens obtidas:', response.data);
    return response.data;
  } catch (error) {
    console.error('Erro ao obter todas as mensagens:', error);
    throw error;
  }
};

const fetchMessagesByConversation = async (userId1, userId2) => {
  // Criar ID da conversa da mesma forma
  const conversationId = [userId1, userId2].sort().join('_');
  
  try {
    const response = await apiService.api.get(`/api/messages/${conversationId}`);
    return response.data || [];
  } catch (error) {
    console.error('Erro ao buscar mensagens:', error);
    return [];
  }
};

const fetchMessageById = async (uidRemetente, uidDestinatario, messageId) => {
  try {
    const response = await apiService.api.get(`/api/messages/${uidRemetente}/${uidDestinatario}/${messageId}`);
    console.log('Mensagem específica:', response.data);
    return response.data;
  } catch (error) {
    console.error('Erro ao obter mensagem específica:', error);
    throw error;
  }
};

// messageService.js

const sendMessage = async (messageData) => {
  try {
    // Criar um ID único para a conversa
    const conversationId = [messageData.uidRemetente, messageData.uidDestinatario]
      .sort() // Garante ordem consistente
      .join('_');

    const formattedMessage = {
      conversationId,
      ...messageData,
      timestamp: Date.now()
    };

    console.log('Enviando mensagem formatada:', formattedMessage);

    const response = await apiService.api.post('/api/messages', formattedMessage);
    
    if (!response.data) {
      throw new Error('Resposta inválida do servidor');
    }

    return {
      id: response.data.id,
      ...formattedMessage
    };
  } catch (error) {
    console.error('Erro detalhado:', {
      error: error.response?.data || error.message,
      status: error.response?.status,
      originalMessage: messageData
    });

    // Lançar erro mais informativo
    if (error.response?.data?.error) {
      throw new Error(`Erro no servidor: ${error.response.data.error}`);
    }
    throw new Error('Erro ao enviar mensagem');
  }
};

const markMessageAsRead = async (userId, messageId, type) => {
  const token = getAuthToken();
  try {
    const response = await apiService.api.post(`/api/messages/${userId}/markAsRead`, {
      headers: {
        'Authorization': `Bearer ${token}`
      },
      userId,
      messageId,
      type
    });
    console.log('Mensagem marcada como lida:', response.data);
    return response.data;
  } catch (error) {
    console.error('Erro ao marcar mensagem como lida:', error);
    throw error;
  }
};

const deleteMessage = async (messageId, uidRemetente, uidDestinatario) => {
  try {
    const response = await apiService.api.delete(`/api/messages/${uidRemetente}/${uidDestinatario}/${messageId}`);
    console.log('Mensagem deletada:', response.data);
    return response.data;
  } catch (error) {
    console.error('Erro ao deletar mensagem:', error);
    throw error;
  }
};

export default {
  fetchAllMessages,
  fetchMessagesByConversation,
  fetchMessageById,
  sendMessage,
  markMessageAsRead,
  deleteMessage
};