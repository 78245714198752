// src/context/MessageContext.js
import React, { createContext, useState, useEffect, useContext, useCallback, useMemo } from 'react';
import messageService from '../services/messageService';
import { useAuth } from './AuthContext';
import { showToast, showPromiseToast } from '../utils/toastUtils';
import { useCachedResource, globalCache } from '../utils/cacheManager';
import { PollingService } from '../utils/pollingService';

// Cache configuration
const CACHE_CONFIG = {
  MESSAGES_KEY: 'user:messages',
  CACHE_TIME: 5 * 60 * 1000, // 5 minutes
  STALE_TIME: 30 * 1000,     // 30 seconds
  POLLING_INTERVAL: 10 * 1000 // 10 seconds
};

const MessageContext = createContext();

export const MessageProvider = ({ children }) => {
  const { currentUser } = useAuth();
  const userId = currentUser?.uid;
  
  // Initialize state with proper structure
  const [state, setState] = useState({
    messages: [],
    unreadCount: 0,
    latestMessage: null,
    activeChats: new Set(),
    loading: true,
    error: null
  });

  // Create polling service instance
  const pollingService = useMemo(() => new PollingService(), []);

  // Cache key for current user's messages
  const cacheKey = useMemo(() => 
    userId ? `${CACHE_CONFIG.MESSAGES_KEY}:${userId}` : null
  , [userId]);

  // Message processing utility
  const processMessage = useCallback((message) => {
    return {
      id: message.id || message._id,
      content: message.conteudo || message.content,
      senderId: message.uidRemetente || message.senderId,
      receiverId: message.uidDestinatario || message.receiverId,
      timestamp: message.timestamp || new Date().toISOString(),
      read: message.lido || false,
      type: message.tipo || 'text',
      metadata: message.metadata || {}
    };
  }, []);

  // Fetch messages from backend
  const fetchMessages = useCallback(async () => {
    if (!userId) {
      setState(prev => ({ ...prev, loading: false }));
      return null;
    }

    try {
      const messages = await messageService.fetchAllMessages(userId);
      const processedMessages = Array.isArray(messages) 
        ? messages.map(processMessage)
        : [];

      const unreadCount = processedMessages.filter(msg => 
        !msg.read && msg.receiverId === userId
      ).length;

      const latestMessage = processedMessages.length > 0
        ? processedMessages.reduce((latest, current) => 
            latest.timestamp > current.timestamp ? latest : current
          )
        : null;

      const activeChats = new Set(
        processedMessages.map(msg => 
          msg.senderId === userId ? msg.receiverId : msg.senderId
        )
      );

      return {
        messages: processedMessages,
        unreadCount,
        latestMessage,
        activeChats,
        loading: false,
        error: null
      };
    } catch (error) {
      console.error('Error fetching messages:', error);
      throw new Error('Failed to load messages');
    }
  }, [userId, processMessage]);

  // Use cached resource for messages
  const {
    data: cachedMessages,
    loading,
    error,
    refetch: refetchMessages,
    invalidate: invalidateMessages
  } = useCachedResource(
    cacheKey,
    fetchMessages,
    {
      cacheTime: CACHE_CONFIG.CACHE_TIME,
      staleTime: CACHE_CONFIG.STALE_TIME,
      onError: (error) => {
        showToast('Error loading messages', { type: 'error' });
        setState(prev => ({
          ...prev,
          error: error.message,
          loading: false
        }));
      }
    }
  );

  // Update state when cached data changes
  useEffect(() => {
    if (cachedMessages) {
      setState(cachedMessages);
    }
  }, [cachedMessages]);

  // Setup message polling
  useEffect(() => {
    if (userId) {
      const pollMessages = async () => {
        try {
          const newState = await fetchMessages();
          if (newState) {
            setState(newState);
            globalCache.setItem(cacheKey, newState, {
              cacheTime: CACHE_CONFIG.CACHE_TIME,
              staleTime: CACHE_CONFIG.STALE_TIME
            });
          }
        } catch (error) {
          console.error('Error polling messages:', error);
        }
      };

      pollingService.addTask('pollMessages', pollMessages, CACHE_CONFIG.POLLING_INTERVAL);

      return () => {
        pollingService.removeTask('pollMessages');
      };
    }
  }, [userId, cacheKey, fetchMessages, pollingService]);

  // Create new message
  const createMessage = useCallback(async (messageData) => {
    let optimisticMessage = null;

    return showPromiseToast(
      (async () => {
        try {
          if (!messageData.content || !messageData.receiverId) {
            throw new Error('Invalid message data');
          }

          const newMessageData = {
            ...messageData,
            senderId: userId,
            timestamp: new Date().toISOString(),
            read: false,
            id: `temp-${Date.now()}-${Math.random().toString(36).substr(2, 9)}` // Temporary ID for optimistic update
          };

          // Create optimistic message
          optimisticMessage = processMessage(newMessageData);

          // Optimistic update
          setState(prev => ({
            ...prev,
            messages: [...prev.messages, optimisticMessage],
            latestMessage: optimisticMessage,
            activeChats: new Set([...prev.activeChats, messageData.receiverId])
          }));

          // Send message to backend
          const response = await messageService.sendMessage(newMessageData);
          const processedMessage = processMessage(response);

          // Update state with actual message
          setState(prev => ({
            ...prev,
            messages: prev.messages.map(msg => 
              msg.id === optimisticMessage.id ? processedMessage : msg
            )
          }));

          // Update cache
          globalCache.setItem(cacheKey, state, {
            cacheTime: CACHE_CONFIG.CACHE_TIME,
            staleTime: CACHE_CONFIG.STALE_TIME
          });

          return processedMessage;
        } catch (error) {
          console.error('Error creating message:', error);
          // Only revert optimistic update if we actually created one
          if (optimisticMessage) {
            setState(prev => ({
              ...prev,
              messages: prev.messages.filter(msg => msg.id !== optimisticMessage.id)
            }));
          }
          throw new Error('Failed to send message');
        }
      })(),
      {
        loading: 'Sending message...',
        success: 'Message sent successfully',
        error: 'Failed to send message'
      }
    );
  }, [userId, cacheKey, state, processMessage]);

  // Delete message
  const deleteMessage = useCallback(async (messageId) => {
    return showPromiseToast(
      (async () => {
        try {
          const messageToDelete = state.messages.find(msg => msg.id === messageId);
          if (!messageToDelete) throw new Error('Message not found');

          // Optimistic update
          setState(prev => ({
            ...prev,
            messages: prev.messages.filter(msg => msg.id !== messageId)
          }));

          await messageService.deleteMessage(
            messageId,
            messageToDelete.senderId,
            messageToDelete.receiverId
          );

          // Update cache
          globalCache.setItem(cacheKey, state, {
            cacheTime: CACHE_CONFIG.CACHE_TIME,
            staleTime: CACHE_CONFIG.STALE_TIME
          });

          return 'Message deleted successfully';
        } catch (error) {
          console.error('Error deleting message:', error);
          // Revert optimistic update
          await refetchMessages();
          throw new Error('Failed to delete message');
        }
      })(),
      {
        loading: 'Deleting message...',
        success: 'Message deleted successfully',
        error: 'Failed to delete message'
      }
    );
  }, [state, cacheKey, refetchMessages]);

  // Mark message as read
  const markMessageAsRead = useCallback(async (messageId) => {
    try {
      // Optimistic update
      setState(prev => ({
        ...prev,
        messages: prev.messages.map(msg => 
          msg.id === messageId ? { ...msg, read: true } : msg
        ),
        unreadCount: prev.unreadCount > 0 ? prev.unreadCount - 1 : 0
      }));

      await messageService.markMessageAsRead(messageId);

      // Update cache
      globalCache.setItem(cacheKey, state, {
        cacheTime: CACHE_CONFIG.CACHE_TIME,
        staleTime: CACHE_CONFIG.STALE_TIME
      });
    } catch (error) {
      console.error('Error marking message as read:', error);
      // Revert optimistic update
      await refetchMessages();
    }
  }, [state, cacheKey, refetchMessages]);

  // Get chat messages
  const getChatMessages = useCallback((otherUserId) => {
    return state.messages.filter(msg => 
      (msg.senderId === userId && msg.receiverId === otherUserId) ||
      (msg.senderId === otherUserId && msg.receiverId === userId)
    ).sort((a, b) => new Date(a.timestamp) - new Date(b.timestamp));
  }, [state.messages, userId]);

  // Context value
  const value = useMemo(() => ({
    messages: state.messages,
    unreadCount: state.unreadCount,
    latestMessage: state.latestMessage,
    activeChats: state.activeChats,
    loading: state.loading || loading,
    error: state.error || error,
    createMessage,
    deleteMessage,
    markMessageAsRead,
    getChatMessages,
    refetchMessages,
    invalidateMessages
  }), [
    state,
    loading,
    error,
    createMessage,
    deleteMessage,
    markMessageAsRead,
    getChatMessages,
    refetchMessages,
    invalidateMessages
  ]);

  return (
    <MessageContext.Provider value={value}>
      {children}
    </MessageContext.Provider>
  );
};

// Custom hook for using the message context
export const useMessages = () => {
  const context = useContext(MessageContext);
  if (context === undefined) {
    throw new Error('useMessages must be used within a MessageProvider');
  }
  return context;
};