// services/sellerService.js
import apiService from './apiService';

export const sellerService = {
  async registerSeller(data) {
    return await apiService.api.post('/api/sellers/register', data);
  },

  async updateSellerProfile(sellerId, data) {
    return await apiService.api.put(`/api/sellers/${sellerId}`, data);
  },

  async getSellerStatus(sellerId) {
    return await apiService.api.get(`/api/sellers/${sellerId}/commission/status`);
  },
};