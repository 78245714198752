import React, { createContext, useContext, useCallback, useMemo } from 'react';
import caixinhaService from '../services/caixinhaService';
import { useAuth } from './AuthContext';
import { showToast, showPromiseToast } from '../utils/toastUtils';
import { useCachedResource, globalCache } from '../utils/cacheManager';
import { validateCaixinhaData } from '../utils/validation';

// Constants
const CAIXINHAS_CACHE_TIME = 5 * 60 * 1000; // 5 minutes
const CAIXINHAS_STALE_TIME = 30 * 1000; // 30 seconds
const SINGLE_CAIXINHA_CACHE_TIME = 2 * 60 * 1000; // 2 minutes
const SINGLE_CAIXINHA_STALE_TIME = 15 * 1000; // 15 seconds

const CaixinhaContext = createContext();

export const CaixinhaProvider = ({ children }) => {
  
  const { currentUser } = useAuth();
  const userId = currentUser?.uid;

  // Cache keys
  const caixinhasCacheKey = useMemo(() => 
    userId ? `caixinhas:list:${userId}` : null
  , [userId]);

  const getCaixinhaCacheKey = useCallback((caixinhaId) => 
    caixinhaId ? `caixinha:${caixinhaId}` : null
  , []);

  // Fetch caixinhas function
  const fetchCaixinhas = useCallback(async () => {
    if (!userId) {
      console.warn('User not authenticated or UID missing');
      return [];
    }

    try {
      console.log('Fetching caixinhas for user:', userId);
      const response = await caixinhaService.getCaixinhas(userId);
      return response.data || [];
    } catch (error) {
      console.error('Error fetching caixinhas:', error);
      throw error;
    }
  }, [userId]);

  // Use cached resource for caixinhas list
  const {
    data: caixinhas,
    loading,
    error,
    refetch: refetchCaixinhas,
    invalidate: invalidateCaixinhas,
  } = useCachedResource(
    caixinhasCacheKey,
    fetchCaixinhas,
    {
      cacheTime: CAIXINHAS_CACHE_TIME,
      staleTime: CAIXINHAS_STALE_TIME,
      enabled: !!userId, // Só ativa a requisição se o userId estiver definido
      onError: (error) => {
        showToast('Error loading caixinhas', { type: 'error' });
      },
    }
  );  

  // Create caixinha
  const createCaixinha = useCallback(async (caixinhaData) => {
    if (!userId) {
      showToast('User must be authenticated', { type: 'error' });
      return;
    }

    // Validate caixinha data
    const validationError = validateCaixinhaData(caixinhaData);
    if (validationError) {
      showToast(validationError, { type: 'error' });
      return;
    }

    return showPromiseToast(
      new Promise(async (resolve, reject) => {
        try {
          const enrichedData = {
            ...caixinhaData,
            adminId: userId,
            dataCriacao: new Date()
          };

          const newCaixinha = await caixinhaService.createCaixinha(enrichedData);
          
          // Update cache
          const currentCaixinhas = globalCache.getItem(caixinhasCacheKey) || [];
          globalCache.setItem(caixinhasCacheKey, [...currentCaixinhas, newCaixinha], {
            cacheTime: CAIXINHAS_CACHE_TIME,
            staleTime: CAIXINHAS_STALE_TIME
          });

          // Refetch to ensure consistency
          await refetchCaixinhas();
          
          resolve(newCaixinha);
        } catch (error) {
          console.error('Error creating caixinha:', error);
          reject(error);
        }
      }),
      {
        loading: 'Creating caixinha...',
        success: 'Caixinha created successfully!',
        error: (error) => `Failed to create caixinha: ${error.message}`
      }
    );
  }, [userId, caixinhasCacheKey, refetchCaixinhas]);

  // Update caixinha
  const updateCaixinha = useCallback(async (id, data) => {
    if (!id) {
      showToast('Invalid caixinha ID', { type: 'error' });
      return;
    }

    // Validate update data
    const validationError = validateCaixinhaData(data, true);
    if (validationError) {
      showToast(validationError, { type: 'error' });
      return;
    }

    return showPromiseToast(
      new Promise(async (resolve, reject) => {
        try {
          const updatedCaixinha = await caixinhaService.updateCaixinha(id, data);
          
          // Update both caches
          const currentCaixinhas = globalCache.getItem(caixinhasCacheKey) || [];
          const updatedCaixinhas = currentCaixinhas.map(cx => 
            cx.id === id ? updatedCaixinha : cx
          );
          
          globalCache.setItem(caixinhasCacheKey, updatedCaixinhas, {
            cacheTime: CAIXINHAS_CACHE_TIME,
            staleTime: CAIXINHAS_STALE_TIME
          });
          
          globalCache.setItem(getCaixinhaCacheKey(id), updatedCaixinha, {
            cacheTime: SINGLE_CAIXINHA_CACHE_TIME,
            staleTime: SINGLE_CAIXINHA_STALE_TIME
          });

          resolve(updatedCaixinha);
        } catch (error) {
          console.error('Error updating caixinha:', error);
          reject(error);
        }
      }),
      {
        loading: 'Updating caixinha...',
        success: 'Caixinha updated successfully!',
        error: (error) => `Failed to update caixinha: ${error.message}`
      }
    );
  }, [caixinhasCacheKey, getCaixinhaCacheKey]);

  // Delete caixinha
  const deleteCaixinha = useCallback(async (id) => {
    if (!id) {
      showToast('Invalid caixinha ID', { type: 'error' });
      return;
    }

    return showPromiseToast(
      new Promise(async (resolve, reject) => {
        try {
          await caixinhaService.deleteCaixinha(id);
          
          // Update cache
          const currentCaixinhas = globalCache.getItem(caixinhasCacheKey) || [];
          const filteredCaixinhas = currentCaixinhas.filter(cx => cx.id !== id);
          
          globalCache.setItem(caixinhasCacheKey, filteredCaixinhas, {
            cacheTime: CAIXINHAS_CACHE_TIME,
            staleTime: CAIXINHAS_STALE_TIME
          });
          
          // Remove single caixinha from cache
          globalCache.invalidate(getCaixinhaCacheKey(id));
          
          resolve('Caixinha deleted successfully');
        } catch (error) {
          console.error('Error deleting caixinha:', error);
          reject(error);
        }
      }),
      {
        loading: 'Deleting caixinha...',
        success: 'Caixinha deleted successfully!',
        error: (error) => `Failed to delete caixinha: ${error.message}`
      }
    );
  }, [caixinhasCacheKey, getCaixinhaCacheKey]);

  // Get single caixinha
  const getCaixinha = useCallback(async (id) => {
    if (!id) {
      showToast('Invalid caixinha ID', { type: 'error' });
      return null;
    }

    const cacheKey = getCaixinhaCacheKey(id);
    const cachedCaixinha = globalCache.getItem(cacheKey);
    
    if (cachedCaixinha && !globalCache.isStale(cacheKey)) {
      return cachedCaixinha;
    }

    return showPromiseToast(
      new Promise(async (resolve, reject) => {
        try {
          const caixinha = await caixinhaService.getCaixinhaById(id);
          
          // Update cache
          globalCache.setItem(cacheKey, caixinha, {
            cacheTime: SINGLE_CAIXINHA_CACHE_TIME,
            staleTime: SINGLE_CAIXINHA_STALE_TIME
          });
          
          resolve(caixinha);
        } catch (error) {
          console.error('Error fetching caixinha:', error);
          reject(error);
        }
      }),
      {
        loading: 'Loading caixinha details...',
        success: 'Caixinha details loaded',
        error: (error) => `Failed to load caixinha: ${error.message}`
      }
    );
  }, [getCaixinhaCacheKey]);

  // Join caixinha
  const joinCaixinha = useCallback(async (caixinhaId, joinData) => {
    if (!userId || !caixinhaId) {
      showToast('Invalid request data', { type: 'error' });
      return;
    }

    return showPromiseToast(
      new Promise(async (resolve, reject) => {
        try {
          const response = await caixinhaService.joinCaixinha(caixinhaId, {
            ...joinData,
            userId
          });
          
          // Invalidate caches
          invalidateCaixinhas();
          globalCache.invalidate(getCaixinhaCacheKey(caixinhaId));
          
          // Refetch data
          await refetchCaixinhas();
          
          resolve(response);
        } catch (error) {
          console.error('Error joining caixinha:', error);
          reject(error);
        }
      }),
      {
        loading: 'Joining caixinha...',
        success: 'Successfully joined caixinha!',
        error: (error) => `Failed to join caixinha: ${error.message}`
      }
    );
  }, [userId, invalidateCaixinhas, refetchCaixinhas, getCaixinhaCacheKey]);

  // Leave caixinha
  const leaveCaixinha = useCallback(async (caixinhaId) => {
    if (!userId || !caixinhaId) {
      showToast('Invalid request data', { type: 'error' });
      return;
    }

    return showPromiseToast(
      new Promise(async (resolve, reject) => {
        try {
          await caixinhaService.leaveCaixinha(caixinhaId, userId);
          
          // Invalidate caches
          invalidateCaixinhas();
          globalCache.invalidate(getCaixinhaCacheKey(caixinhaId));
          
          // Refetch data
          await refetchCaixinhas();
          
          resolve('Successfully left caixinha');
        } catch (error) {
          console.error('Error leaving caixinha:', error);
          reject(error);
        }
      }),
      {
        loading: 'Leaving caixinha...',
        success: 'Successfully left caixinha!',
        error: (error) => `Failed to leave caixinha: ${error.message}`
      }
    );
  }, [userId, invalidateCaixinhas, refetchCaixinhas, getCaixinhaCacheKey]);

  // Invite member
  const inviteMember = useCallback(async (caixinhaId, inviteData) => {
    if (!caixinhaId || !inviteData.email) {
      showToast('Invalid invite data', { type: 'error' });
      return;
    }

    return showPromiseToast(
      new Promise(async (resolve, reject) => {
        try {
          const response = await caixinhaService.inviteMember(caixinhaId, inviteData);
          resolve(response);
        } catch (error) {
          console.error('Error inviting member:', error);
          reject(error);
        }
      }),
      {
        loading: 'Sending invitation...',
        success: 'Invitation sent successfully!',
        error: (error) => `Failed to send invitation: ${error.message}`
      }
    );
  }, []);

  // Context value
  const value = useMemo(() => ({
    caixinhas: caixinhas || [],
    loading,
    error,
    createCaixinha,
    updateCaixinha,
    deleteCaixinha,
    getCaixinha,
    joinCaixinha,
    leaveCaixinha,
    inviteMember,
    refetchCaixinhas
  }), [
    caixinhas,
    loading,
    error,
    createCaixinha,
    updateCaixinha,
    deleteCaixinha,
    getCaixinha,
    joinCaixinha,
    leaveCaixinha,
    inviteMember,
    refetchCaixinhas
  ]);

  return (
    <CaixinhaContext.Provider value={value}>
      {children}
    </CaixinhaContext.Provider>
  );
};

export const useCaixinha = () => {
  const context = useContext(CaixinhaContext);
  if (context === undefined) {
    throw new Error('useCaixinha must be used within CaixinhaProvider');
  }
  return context;
};