//frontend/src/components/Dashboard/Dashboard.js
import React, { useEffect, useState } from 'react';
import { Container, CircularProgress, Typography, Grid, Card, CardActions, CardContent, Button, List, ListItem, ListItemAvatar, Avatar, ListItemText, ListItemSecondaryAction, IconButton } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import { MessageSharp, FavoriteSharp, PersonSharp, ThumbUpSharp, CardGiftcardSharp, InfoSharp } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import getDashboardData from '../../services/dashboardService';
import { useAuth } from '../../context/AuthContext';
import { useNotifications } from '../../context/NotificationContext';
import DashboardProfile from '../Profiles/DashboardProfile';
import { showToast } from '../../utils/toastUtils';

const Dashboard = () => {
  const { t, i18n } = useTranslation();
  const { state: { currentUser, isAuthenticated, initialized } } = useAuth();
  const isLoggedIn = localStorage.getItem('userId');
  const { notifications, markAsRead } = useNotifications();
  const [loading, setLoading] = useState(true);
  const [dashboardData, setDashboardData] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    let isMounted = true;
  
    const fetchData = async () => {
      if (!currentUser?.uid) {
        console.error('Usuário não autenticado ou UID indefinido.');
        navigate('/login');
        return;
      }
  
      if (!isMounted) return;
  
      setLoading(true);
      try {
        const userId = currentUser.uid;
        const response = await getDashboardData(userId);
        if (isMounted && response) {
          setDashboardData(response);
          showToast(t('dashboard.dataLoaded'), { type: 'success' });
        }
      } catch (error) {
        if (isMounted) {
          console.error('[Dashboard] Erro ao buscar dados:', error);
          showToast(t('dashboard.failedToLoadData'), { type: 'error' });
        }
      } finally {
        if (isMounted) {
          setLoading(false);
        }
      }
    };
  
    if (currentUser?.uid) {
      fetchData();
    }
  
    return () => {
      isMounted = false;
    };
  }, [currentUser?.uid, navigate]);

  if (loading) {
    return (
      <Container>
        <CircularProgress />
      </Container>
    );
  }

  if (!dashboardData) {
    return (
      <Container>
        <Typography variant="h5">{t('dashboard.noData')}</Typography>
      </Container>
    );
  }

  if (!i18n.isInitialized) {
    return <CircularProgress />;
  }

  const handleMarkAsRead = async (notificationId, type) => {
    const userId = currentUser.uid;
    if (!userId) {
      return;
    }
    try {
      await markAsRead(notificationId, type);
      showToast(t('dashboard.notificationMarkedAsRead'), { type: 'info' });
    } catch (error) {
      showToast(t('dashboard.errorMarkingNotification'), { type: 'error' });
      console.error('Error marking notification as read:', error);
    }
  };

  return (
    <Container>
      <Typography variant="h4" gutterBottom>
        {t('dashboard.title')}
      </Typography>
      <DashboardProfile />
      <Grid container spacing={3}>
  {dashboardData.caixinhas && dashboardData.caixinhas.length > 0 ? (
    dashboardData.caixinhas.map((item) => (
      <Grid item xs={12} sm={6} md={4} key={uuidv4()}>
        <Card sx={{ boxShadow: 3, borderRadius: 2 }}>
          <CardContent>
            <Typography variant="h5" gutterBottom>
              {item.name}
            </Typography>
            <Typography variant="body1" color="textSecondary" gutterBottom>
              {item.description || 'Nenhuma descrição fornecida'}
            </Typography>
            <Typography variant="h6" color="primary">
              {t('dashboard.caixinhas.saldoTotal')}: R$ {item.saldoTotal.toFixed(2)}
            </Typography>
            <Typography variant="body2" color="textSecondary">
              {t('dashboard.caixinhas.contribuicaoMensal')}: R$ {item.contribuicaoMensal.toFixed(2)}
            </Typography>
            <Typography variant="body2" color="textSecondary">
              {t('dashboard.caixinhas.diaVencimento')}: {item.diaVencimento}
            </Typography>
            <Typography variant="body2" color="textSecondary">
              {t('dashboard.caixinhas.valorJuros')}: {item.valorJuros}%
            </Typography>
            <Typography variant="body2" color="textSecondary">
              {t('dashboard.caixinhas.valorMulta')}: R$ {item.valorMulta.toFixed(2)}
            </Typography>
            <Typography variant="body2" color="textSecondary">
              {t('dashboard.caixinhas.distribuicaoTipo')}: {item.distribuicaoTipo}
            </Typography>
            <Typography variant="caption" display="block" color="textSecondary" mt={2}>
              {t('dashboard.caixinhas.dataCriacao')}: {new Date(item.dataCriacao).toLocaleDateString()}
            </Typography>
          </CardContent>
          <CardActions>
            <Button variant="contained" color="primary">
              {t('dashboard.caixinhas.verDetalhes')}
            </Button>
          </CardActions>
        </Card>
      </Grid>
    ))
  ) : (
    <Grid item xs={12}>
      <Card>
        <CardContent>
          <Typography variant="h5" color="textSecondary">
            {t('dashboard.caixinhas.noCaixinhas')}
          </Typography>
        </CardContent>
      </Card>
    </Grid>
  )}

<Grid item xs={12} sm={6} md={4}>
  <Card sx={{ maxWidth: 300, margin: 1 }}>
    <CardContent>
      <Typography variant="h5">
        {notifications.filter((n) => !n.lida).length > 0
          ? t('dashboard.notifications.newNotifications', { count: notifications.filter((n) => !n.lida).length })
          : t('dashboard.notifications.noNewNotifications')}
      </Typography>
      {notifications.slice(0, 3).map((notification) => (
        <Card key={uuidv4()} sx={{ marginBottom: 2 }}>
          <CardContent>
            <Typography variant="h6" component="h2">
              {notification.title}
            </Typography>
            <Typography variant="body2" color="textSecondary">
              {notification.type === 'reaction' ? <ThumbUpSharp /> : notification.type === 'gift' ? <CardGiftcardSharp /> : <InfoSharp />}
              {notification.content}
            </Typography>
            <Typography variant="caption" color="textSecondary">
              {notification.lida
                ? `${t('dashboard.readAt')} ${notification.lidaAt}`
                : `${t('dashboard.receivedAt')} ${notification.receivedAt}`}
            </Typography>
          </CardContent>
          <CardActions>
            <Button size="small" color="primary">
              {t('dashboard.notifications.viewEvent')}
            </Button>
            <Button size="small" color="primary" onClick={() => handleMarkAsRead(notification.id, notification.type)}>
              {notification.lida ? t('dashboard.notifications.markAsUnread') : t('dashboard.notifications.markAsRead')}
            </Button>
            <Button size="small" color="primary">
              {t('dashboard.notifications.viewCalendar')}
            </Button>
          </CardActions>
        </Card>
      ))}
      <Button variant="contained" color="primary" onClick={() => navigate('/notifications')}>
        {notifications.filter((n) => !n.lida).length > 0
          ? t('dashboard.notifications.viewAll')
          : t('dashboard.notifications.viewHistory')}
      </Button>
    </CardContent>
  </Card>
</Grid>

        {dashboardData.messages && dashboardData.messages.length > 0 ? (
          dashboardData.messages.map((item) => (
            <Grid item xs={12} sm={6} md={4} key={uuidv4()}>
              <Card>
                <CardContent>
                  <Typography variant="h5">{t('dashboard.messages.from')} {item.userId}</Typography>
                  <Typography>{item.content}</Typography>
                </CardContent>
              </Card>
            </Grid>
          ))
        ) : (
          <Grid item xs={12} sm={6} md={4}>
            <Card>
              <CardContent>
                <Typography variant="h5">{t('dashboard.messages.noMessages')}</Typography>
              </CardContent>
            </Card>
          </Grid>
        )}

        <Grid item xs={12} sm={6} md={4}>
          <Card>
            <CardContent>
              <Typography variant="h5">{t('dashboard.friends.title')}</Typography>
              <List>
                {dashboardData.connections.friends.map((connection) => (
                  <ListItem key={uuidv4()}>
                    <ListItemAvatar>
                      <Avatar src={connection.fotoDoPerfil} />
                    </ListItemAvatar>
                    <ListItemText primary={connection.nome} secondary={t('dashboard.friends.connected', { duration: connection.connectionDuration })} />
                    <ListItemSecondaryAction>
                      <IconButton edge="end" aria-label="view-profile">
                        <PersonSharp />
                      </IconButton>
                      <IconButton edge="end" aria-label="send-message">
                        <MessageSharp />
                      </IconButton>
                      <IconButton edge="end" aria-label="make-best-friend">
                        <FavoriteSharp />
                      </IconButton>
                    </ListItemSecondaryAction>
                  </ListItem>
                ))}
              </List>
            </CardContent>
          </Card>
        </Grid>

        {dashboardData.connections && dashboardData.connections.bestFriends && dashboardData.connections.bestFriends.length > 0 ? (
          dashboardData.connections.bestFriends.map((item) => (
            <Grid item xs={12} sm={6} md={4} key={uuidv4()}>
              <Card>
                <CardContent>
                  <Typography variant="h5">{t('dashboard.bestFriends.title')}: {item.nome}</Typography>
                  <Typography>{item.email}</Typography>
                </CardContent>
              </Card>
            </Grid>
          ))
        ) : (
          <Grid item xs={12} sm={6} md={4}>
            <Card>
              <CardContent>
                <Typography variant="h5">{t('dashboard.bestFriends.noBestFriends')}</Typography>
              </CardContent>
            </Card>
          </Grid>
        )}
      </Grid>
    </Container>
  );
};

export default Dashboard;